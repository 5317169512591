import {
	ADD_FAQS_FAIL,
	GET_FAQS,
	GET_PRIVACY_POLICY,
	GET_TERMS_CONDITIONS,
	LOGOUT,
} from "../Actions/ActionTypes";

let init = {
	data: [],
	isAdded: false,
	isDeleted: false,
	terms: [],
	privacy: [],
};

export const FaqsReducer = (state = init, action) => {
	let { type, payload } = action;
	let data = payload?.data ? payload?.data : payload;

	switch (type) {
		case GET_FAQS:
			return { ...state, data };
		case GET_TERMS_CONDITIONS:
			return { ...state, terms: data };
		case GET_PRIVACY_POLICY:
			return { ...state, privacy: data };
		case ADD_FAQS_FAIL:
			return { ...state, isAdded: false, isDeleted: false };
		case LOGOUT:
			return init;
		default:
			return state;
	}
};
