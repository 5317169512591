import React, { useState, useContext, useEffect } from "react";
import { Container } from "reactstrap";
import { Buttons } from "../../Utils";
import { ModalComponents } from "..";
import { GlobalState } from "../../Data/Context";
import LoadMore, { BottomTab } from "../LoadMore";
import {
	TransactionDetails,
	NewPaginate,
} from "../Transactions";
import { AirtimeModalOption } from "./AutoBuy";
import { toast } from "react-toastify";

const Airtime = () => {
	let [isOpen, setIsOpen] = useState(false),
		toggle = () => {
			setIsOpen(!isOpen);
		};

	let { setStateName, airtimes, usecase } = useContext(GlobalState);
	useEffect(() => {
		setStateName("airtime history");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (usecase?.usecase?.airtime === "enable") setIsOpen(true);

		return () => setIsOpen(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [usecase?.usecase?.airtime]);

	let [submit, setSubmit] = useState(false);

	useEffect(() => {
		if (airtimes?.isAdded && submit) {
			setIsOpen(false);
			setSubmit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [airtimes?.isAdded, submit]);

	return (
		<div className="bg-white aboutScreen">
			<Container className="py-5">
				<Buttons
					title={"buy airtime"}
					css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
					width={"w-25 w25"}
					onClick={
						usecase?.usecase ?usecase?.usecase?.airtime === "enable"
							? toggle
							: () => {
									toast.info(
										"This service is currently not available, please try again later"
									);
							  }: toggle
					}
					style={{ borderRadius: "30px" }}
				/>
				<AirtimeHistory />
			</Container>
			<ModalComponents title="buy airtime" isOpen={isOpen} toggle={toggle}>
				<AirtimeModalOption setSubmit={setSubmit} />
			</ModalComponents>
		</div>
	);
};

export default Airtime;

const AirtimeHistory = () => {
	let { airtimes, getServicesHistory, getReload } = useContext(GlobalState);

	let [data, setData] = useState(null),
		[thisData, setThisData] = useState(null);
	let [loading, setLoading] = useState(false),
		[search, setSearch] = useState("");

	useEffect(() => {
		getServicesHistory("airtime");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (search) {
			document.getElementById("Search").addEventListener("search", () => {
				getReload();
			});
			let handleSubmit = async () => {
				if (!search) return;

				await getServicesHistory("airtime", {
					search,
				});
			};
			handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	useEffect(() => {
		if (airtimes.isFound) {
			setData(airtimes.mainSearch);
		} else setData(airtimes.airtime);
	}, [airtimes.airtime, airtimes.isFound, airtimes.mainSearch]);

	useEffect(() => {
		getReload();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let handleLoadMore = async () => {
		setLoading(true);

		await getServicesHistory("airtime", {
			page: Number(airtimes?.paginate?.nextPage),
			// limit: Number(airtimes?.paginate?.nextPage * airtimes?.paginate?.limit),
			search,
		});

		setLoading(false);
	};

	if (!data) return;
	// console.log({ data });

	return (
		<div className="pb-5 my-5">
			<div className="w-50 w50 mb-3">
				<input
					type="search"
					name="search"
					id="Search"
					className="form-control w-100 py-3 borderColor2"
					placeholder="Type here to search"
					value={search}
					onChange={e => setSearch(e.target.value)}
				/>
			</div>
			<NewPaginate
				state={data}
				setState={setData}
				setThisData={setThisData}
				type={"airtime"}
				criteria={
					{
						// id: params?.step,
					}
				}
			/>
			<TransactionDetails
				thisData={thisData}
				setThisData={setThisData}
				type={"airtime"}
				criteria={
					{
						// id: params?.step,
					}
				}
			/>
			<BottomTab
				state={data}
				paginate={search ? airtimes?.search_paginate : airtimes?.paginate}
			/>
			<LoadMore
				next={
					search ? airtimes?.search_paginate?.next : airtimes?.paginate?.next
				}
				handleLoadMore={handleLoadMore}
				loading={loading}
			/>
		</div>
	);
};

export const NetworkList = ({ state, setState }) => {
	let { network } = useContext(GlobalState);
	return (
		<div className="row mx-0">
			{network?.data?.map((item, i) => (
				<div
					className="col p-2"
					onClick={setState ? () => setState(item?.name) : () => {}}
					key={i}>
					<div
						style={{
							height: "3rem",
							width: "3rem",
						}}
						className={`rounded d-flex myCursor ${
							state === item?.name
								? "borderColor borderColor2 list-group-item-primary"
								: ""
						}`}>
						<img
							src={item?.image?.url}
							alt={item?.image?.name}
							className="img-fluid imgFluid h-75 m-auto"
						/>
					</div>
				</div>
			))}
		</div>
	);
};

export const DataNetworkList = ({ state, setState }) => {
	let { data } = useContext(GlobalState);
	return (
		<div className="row mx-0">
			{[...new Set(data?.dataToBuy?.map(item => item?.category?.categoryId))]
				?.sort()
				?.reverse()
				?.map((item, i) => (
					<DataLister setState={setState} state={state} item={item} key={i} />
				))}
		</div>
	);
};

let DataLister = ({ item, setState, state }) => {
	let { network } = useContext(GlobalState),
		[list, setList] = useState(null);

	useEffect(() => {
		setList(
			network?.data?.find(i =>
				item?.toLowerCase()?.includes(i?.name?.toLowerCase())
			)
		);
	}, [item, network]);

	if (!list) return;

	return (
		<div
			className="col-3 p-2 tw-flex tw-flex-col tw-justify-center"
			onClick={() => setState(item)}>
			<div
				style={{
					height: "3.5rem",
					width: "3.5rem",
				}}
				className={`rounded d-flex myCursor justify-content-center tw-mx-auto ${
					state === item
						? "borderColor borderColor2 list-group-item-primary"
						: ""
				}`}>
				<img
					src={list?.image?.url}
					alt={list?.image?.name}
					className="img-fluid imgFluid h-75 w-75 m-auto"
				/>
			</div>
			{item && (
				<small className="Lexend text-uppercase text-center">
					{item?.includes("_") ? item?.slice(item?.indexOf("_") + 1) : item}
				</small>
			)}
		</div>
	);
};

export const NetworkList2 = ({ state }) => {
	let { network } = useContext(GlobalState);
	return (
		<div
			style={{
				height: "5rem",
				width: "5rem",
			}}
			className={`rounded d-flex`}>
			<img
				src={network?.data?.find?.(item => state === item?.name)?.image?.url}
				alt={network?.data?.find?.(item => state === item?.name)?.image?.name}
				className="img-fluid imgFluid h-75 w-75 m-auto"
			/>
		</div>
	);
};
