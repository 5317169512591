import logo1 from "../../Assets/logo1.png";
import logo2 from "../../Assets/logo2.png";
import logo3 from "../../Assets/logo3.png";
import logo4 from "../../Assets/logo4.png";
import logo5 from "../../Assets/logo5.png";
import logo6 from "../../Assets/logo6.png";

const Companies = () => {
  return (
    <div className="tw-my-20 container">
      <p className="tw-text-2xl tw-font-bold tw-text-center tw-text-black">
        Trusted by{" "}
        <span className="tw-text-2xl tw-text-[#2F75FD]">4,000+ </span>
        companies
      </p>
      <marquee>
        <div className="tw-flex tw-items-center tw-justify-between">
          <img src={logo1} alt="Netflix logo" />
          <img src={logo2} alt="Canva logo" />
          <img src={logo3} alt="Adobe logo" />
          <img src={logo4} alt="grammarly logo" />
          <img src={logo5} alt="airbnb logo" />
          <img src={logo6} alt="amazon logo" />
        </div>
      </marquee>
    </div>
  );
};

export default Companies;
