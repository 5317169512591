import ScrollIntoView from "react-scroll-into-view";
import AppLogo from "../app-logo";
const Footer = () => {
  return (
    <footer
      id="contact"
      className="tw-text-black container tw-mt-10 tw-font-medium md:tw-px-20 tw-px-5 tw-py-5"
    >
      <div className="md:tw-flex tw-justify-between">
        <div className="">
          <AppLogo />
          <ul className="md:tw-flex tw-cursor-pointer tw-w-96 tw-items-center tw-justify-between tw-text-2xl">
            <ScrollIntoView selector="#home">
              <li className="md:tw-my-0 tw-my-2">Home</li>
            </ScrollIntoView>
            <ScrollIntoView selector="#about">
              <li className="md:tw-my-0 tw-my-2">About</li>
            </ScrollIntoView>
            <ScrollIntoView selector="#product">
              <li className="md:tw-my-0 tw-my-2">Features</li>
            </ScrollIntoView>
            <ScrollIntoView selector="#product">
              <li className="md:tw-my-0 tw-my-2">Pricing</li>
            </ScrollIntoView>
            <ScrollIntoView selector="#contact">
              <li className="md:tw-my-0 tw-my-2">Contact</li>
            </ScrollIntoView>
          </ul>
        </div>
        {/* <div className="">
          <h4 className="tw-text-md md:tw-my-0 tw-my-2 mb-2 tw-font-medium">Stay up to date</h4>
          <div className="tw-bg-white tw-p-1 tw-rounded-3xl tw-flex">
            <input
              type="email"
              className="tw-bg-transparent tw-border-none tw-outline-none"
              placeholder="Enter your email"
            />
            <button className="tw-bg-[#2F75FD] tw-px-3 tw-rounded-3xl">Subscribe</button>
          </div>
        </div> */}
      </div>
      <div className="tw-border-2 tw-border-gray-400  tw-my-8"></div>
      <div className="md:tw-flex tw-justify-between">
        <tt className="md:tw-block tw-hidden">
          @2022 HonourWorld. All rights reserved
        </tt>
        <ul className="tw-flex tw-items-center tw-w-72 tw-font-medium tw-justify-between">
          <li>Terms</li>
          <li>
            <a
              className="text-decoration-none text-white"
              href="https://api.honourworld.com/policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </a>
          </li>
          <li>Cookies</li>
        </ul>
        <tt className="md:tw-hidden tw-block tw-my-3 tw-text-center">
          @2022 HonourWorld. All rights reserved
        </tt>
      </div>
    </footer>
  );
};

export default Footer;
