import AnimaionLayout from "../animeLayout/animeLayout";

import { BsArrowDownCircle, BsArrowUpCircle } from "react-icons/bs";

import { useState } from "react";

const Questions = [
  {
    question: "What is HonorWorld",
    answer:
      "Cardpay is a modern banking solution that allows you to manage your finances from your phone or computer. Our user-friendly app and secure online platform provide you with a convenient and efficient way to access all of your financial services in one place",
  },
  {
    question: "What is HonorWorld",
    answer:
      "Cardpay is a modern banking solution that allows you to manage your finances from your phone or computer. Our user-friendly app and secure online platform provide you with a convenient and efficient way to access all of your financial services in one place",
  },
  {
    question: "Is HonorWorld secure?",
    answer:
      "Cardpay is a modern banking solution that allows you to manage your finances from your phone or computer. Our user-friendly app and secure online platform provide you with a convenient and efficient way to access all of your financial services in one place",
  },
  {
    question: "What services does HonorWorld offer?",
    answer:
      "Cardpay is a modern banking solution that allows you to manage your finances from your phone or computer. Our user-friendly app and secure online platform provide you with a convenient and efficient way to access all of your financial services in one place",
  },
  {
    question: "Is HonorWorld free to use?",
    answer:
      "Cardpay is a modern banking solution that allows you to manage your finances from your phone or computer. Our user-friendly app and secure online platform provide you with a convenient and efficient way to access all of your financial services in one place",
  },
  {
    question: "Can I use HonorWorld outside of the country?",
    answer:
      "Cardpay is a modern banking solution that allows you to manage your finances from your phone or computer. Our user-friendly app and secure online platform provide you with a convenient and efficient way to access all of your financial services in one place",
  },
];
const FAQ = () => {
  const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
      setIsOpen(!isOpen);
    };

    return (
      <div id="contact" className="tw-border-b-2 container tw-border-[#12155A] tw-my-2 tw-p-4">
        <div className="tw-flex tw-items-center tw-justify-between">
          <h2 className="tw-my-2 tw-font-semibold tw-text-black">{question}</h2>
          <span onClick={toggleOpen} className="tw-cursor-pointer tw-text-[#1b1b1b]">
            {isOpen ? <BsArrowUpCircle /> : <BsArrowDownCircle />}
          </span>
        </div>
        {isOpen && <p className=" tw-my-3 tw-font-light tw-text-[#1b1b1b]">{answer}</p>}
      </div>
    );
  };

  return (
    <AnimaionLayout className="">
      <p className="tw-text-4xl tw-font-bold tw-text-center tw-text-black">
        Frequently Asked{" "}
        <span className="tw-text-4xl tw-text-[#2F75FD]">Questions</span>
      </p>
      <p className="tw-text-center tw-my-3 tw-font-light tw-text-black">
        Everything you need to know about the product and billing.
      </p>
      <section className="tw-flex  tw-relative tw-max-w-4xl r tw-mx-auto tw-flex-col">
        <span className="tw-block tw-bg-[#211F63] tw-blur-[128px] tw-top-48  -tw-translate-y-1/2  -tw-left-10 tw-border-0  tw-rounded-full tw-absolute -tw-z-10 tw-h-64 tw-w-64"></span>
        {Questions.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </section>
    </AnimaionLayout>
  );
};

export default FAQ;
