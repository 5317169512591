import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../Data/Context";
import Calendar from "react-calendar";
import { Container } from "reactstrap";
import "react-calendar/dist/Calendar.css";
// import Charts, { LineMixedCharts } from "./Charts";
import { useNavigate } from "react-router-dom";
import TransactionsFolder from "./Transactions";
import { colors, productArr } from "./Products";
import { Icon } from "@iconify/react";

const Dashboard = ({ usersArr }) => {
  let { setStateName } = useContext(GlobalState);
  useEffect(() => {
    setStateName("dashboard analysis");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tw-bg-white aboutScreen">
      <Container className="py-5">
        <div className="row mx-0 w-100">
          <div className="">
            {/* <FourBoxBar list={usersArr} css="col-md-4" /> */}
            <ProductList />
          </div>
          {/* <MainCalenderComponent /> */}
        </div>
        <TransactionsFolder />
      </Container>
    </div>
  );
};

export default Dashboard;

export const MainCalenderComponent = () => {
  return (
    <>
      <div className="col-md-4 d-none d-md-flex flex-column">
        <CalenderComponent css="darkPurple rounded20 border-0 p-3 text-white w-100" />
      </div>
    </>
  );
};

export const CalenderComponent = ({ css }) => {
  return (
    <>
      <div className="d-flex w-100">
        <Calendar className={css} showWeekNumbers={true} calendarType="US" />
      </div>
    </>
  );
};

export const FourBoxBar = ({ list, css }) => {
  let navigate = useNavigate();
  return (
    <>
      <div className="row mx-0 g-2 g-md-4 mb-4 mb-md-5">
        {list?.map((item, index) => (
          <div
            className={`col-6 dashHeight dashHeight2 ${css || ""}`}
            key={index}
          >
            <div
              className="row mx-0 p-2 p-md-3 eachProduct rounded20 text-white h-100"
              onClick={() => (item?.link ? navigate(item?.link) : {})}
              style={{
                background: item?.color,
              }}
            >
              <div className="col-md my-auto d-flex">
                <img
                  src={item?.icon}
                  className="img-fluid mx-auto"
                  alt="Icon"
                />
              </div>
              <div className="col-md my-auto text-center">
                <p className="text2 text2Big m-0">{item?.number}</p>
                <h6 className="text-capitalize fontReduceBig">{item?.name}</h6>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export const ProductList = () => {
  let { auth } = useContext(GlobalState);
  let [state, setState] = useState([]),
    navigate = useNavigate();

  useEffect(() => {
    setState(
      !["agent"]?.includes(auth?.user?.privilege)
        ? productArr?.filter((item) => item?.link !== "/cgwallet")
        : productArr
    );
  }, [auth?.user]);
  return (
    <div className="py-3 py-md-4">
      <div className="tw-grid lg:tw-grid-cols-8 lg:tw-gap-2 tw-gap-y-5 tw-grid-cols-2">
        {state?.map((item, i) => (
          <div className="px-1 p-md-3 text-center tw-mt-2" key={i}>
            <div
              onClick={() =>
                navigate(
                  item?.link?.includes("converter")
                    ? item?.link
                    : `/products${item?.link}`
                )
              }
              className=" py-md-3 eachProduct shadow-xl rounded20 h-100 d-flex align-items-center justify-content-center"
            >
              <div className="d-flex flex-column">
                <div className="tw-flex tw-justify-center tw-mb-2">
                  <Icon
                    color={colors[i % colors.length]}
                    style={{
                      fontSize: "36px",
                    }}
                    icon={item?.icon}
                  />
                  {/* <img src={item.icon2} alt="" className="tw-h-16" /> */}
                </div>
                <h6 className="Lexend textTrunc textTrunc2 fontReduce2 text-capitalize">
                  {item?.name}
                </h6>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
