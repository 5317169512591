import React from "react";
import { useState } from "react";
import { EyeToggle } from "../../Utils";

const PasswordInput = ({ Icon, value, onChange, name, placeholder }) => {
  const [typePass, setTypePass] = useState(false);
  return (
    <div>
      <div
        style={{
          borderRadius: "8px",
          border: "1.5px solid #000",
          backdropFilter: "blur(2px)",
        }}
        className="tw-relative tw-flex tw-gap-3 tw-items-center tw-pl-6 tw-w-full tw-h-14 tw-bg-white"
      >
        <div className='tw-absolute tw-top-4'>



        <img src={Icon} alt="" className="" />
</div>
        <div className="tw-flex tw-ml-8 tw-justify-between tw-h-full tw-w-full tw-pr-8 tw-items-center">
          <input
            type={typePass ? "text" : "password"}
            required
            name={name}
            style={{
              border: 'none !important'
            }}
            className="tw-w-full tw-h-full tw-border-none tw-text-black tw-font-normal tw-text-xl general tw-rounded-lg"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          />
          <EyeToggle typePass={typePass} setTypePass={setTypePass} />
        </div>
      </div>
    </div>
  );
};

export default PasswordInput;
