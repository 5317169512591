const Card = ({ img, title, text }) => {
  return (
    <div data-aos="zoom-in-up" data-aos-duration="2000" className="tw-text-center tw-bg-white tw-rounded-lg tw-p-8 tw-shadow-2xl">
      <img
        src={require(`../../Assets/${img}`)}
        className="tw-mx-auto tw-h-10"
        alt=""
      />
      <p className="tw-font-bold tw-mt-8">{title}</p>
      <p className="tw-font-light tw-mt-4">
        {text}
      </p>
    </div>
  );
};

export default Card;
