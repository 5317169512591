import React from "react";
import { AiOutlineMail } from "react-icons/ai";

const TextInput = ({
  Icon,
  name,
  placeholder,
  value,
  onChange,
  type,
  maxLength,
  readOnly,
  required,
  img,
}) => {
  return (
    <div>
      <div
        style={{
          borderRadius: "8px",
          border: "1.5px solid #000",
          // backdropFilter: "blur(2px)",
        }}
        className="tw-relative tw-flex tw-items-center tw-w-full tw-h-14 tw-bg-white"
      >
        <div className="tw-absolute tw-top-4 tw-left-6">
          {img ? (
            <img src={Icon} alt="" className="" />
          ) : (
            <AiOutlineMail size={20} />
          )}
        </div>

        <input
          type={type}
          required={required}
          name={name}
          className="tw-h-full tw-text-black tw-pl-16 tw-w-full tw-font-medium tw-text-xl general tw-rounded-lg"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

export default TextInput;
