import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { Buttons } from "../../Utils";
import { ModalComponents } from "..";
import { GlobalState } from "../../Data/Context";
import { useValidation } from "../../Data/useFetch";
import LoadMore, { BottomTab } from "../LoadMore";
import { TransactionDetails, NewPaginate } from "../Transactions";
import { TransactionPinBox } from "./AutoBuy";
import { toast } from "react-toastify";
import { BiCopy } from "react-icons/bi";
import { NumericFormat } from "react-number-format";
import { returnErrors } from "../../Data/Reducer/ErrorReducer";

const TVSub = () => {
	let { setStateName, cables, numberWithCommas, usecase, nairaSign } =
		useContext(GlobalState);
	let [isOpen, setIsOpen] = useState(false),
		toggle = () => {
			setIsOpen(!isOpen);
			if (clickedData) setClickedData(null);
		},
		init = {
			type: "",
			productsCode: "",
			packagename: "",
			amount: "",
			user: "",
			smartCardNo: "",
			pin: "",
			dstvaddonCode: "",
		},
		[state, setState] = useState(init),
		[thisData, setThisData] = useState(false),
		[active, setActive] = useState(0),
		btnTab = ["cable history", "cable list"],
		[clickedData, setClickedData] = useState(null);

	useEffect(() => {
		setStateName("cable history");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (usecase?.usecase?.cables === "enable") setIsOpen(true);
		return () => setIsOpen(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [usecase?.usecase?.cables]);

	useEffect(() => {
		if (clickedData) {
			setState({
				...state,
				amount: clickedData?.price,
				packagename: clickedData?.name,
				productsCode: clickedData?.code,
				type: clickedData?.type,
			});
			setIsOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clickedData]);

	return (
		<div className="bg-white aboutScreen">
			<Container className="py-5">
				<Buttons
					title={"subscribe"}
					css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
					width={"w-25 w25"}
					onClick={
						usecase?.usecase
							? usecase?.usecase?.cables === "enable"
								? toggle
								: () => {
										toast.info(
											"This service is currently not available, please try again later"
										);
								  }
							: toggle
					}
					style={{ borderRadius: "30px" }}
				/>
				<div className="btn-group w-100 py-3">
					{btnTab?.map((item, i) => (
						<button
							key={i}
							className={`btn py-3 text-capitalize fw-bold ${
								i === active ? "border-bottom textColor" : ""
							} rounded-0`}
							onClick={() => setActive(i)}>
							{item}
						</button>
					))}
				</div>
				{active === 0 ? (
					<TVSubHistory setThisData={setThisData} thisData={thisData} />
				) : (
					<>
						<h5 className="Lexend mb-3 fontReduceBig">Cable List</h5>
						<div className="row mx-0 p-3 bland">
							<div className="col my-auto textTrunc fontReduce fw-bold Lexend">
								S/N
							</div>
							<div className="col my-auto textTrunc fontReduce fw-bold Lexend">
								Logo
							</div>
							<div className="col my-auto textTrunc fontReduce fw-bold Lexend">
								Name
							</div>
						</div>
						{cables?.cable_direct?.map((item, i) => (
							<div className="row mx-0 p-3 border-bottom" key={i}>
								<div className="col my-auto textTrunc fontReduce2">{i + 1}</div>
								<div className="col my-auto textTrunc fontReduce2">
									<img
										src={item?.image?.url}
										alt={item?.image?.name}
										className="img-fluid rounded imgFluid"
										style={{
											height: "10rem",
											width: "auto",
										}}
									/>
								</div>
								<div className="col my-auto textTrunc fontReduce2 text-uppercase">
									{item?.name}
								</div>
							</div>
						))}
						<h5 className="Lexend my-3 fontReduceBig">DStv List</h5>
						<div className="row mx-0 p-3 bland">
							<div className="col my-auto textTrunc fontReduce fw-bold Lexend">
								S/N
							</div>
							<div className="col my-auto textTrunc fontReduce fw-bold Lexend">
								Name
							</div>
							<div className="col my-auto textTrunc fontReduce fw-bold Lexend">
								Price
							</div>
						</div>
						{!Array?.isArray(cables?.cable_package?.dstv)
							? null
							: cables?.cable_package?.dstv?.map((item, i) => (
									<div
										className="row mx-0 p-3 border-bottom myCursor"
										// onClick={() => setClickedData({ ...item, type: "DSTV" })}
										key={i}>
										<div className="col my-auto textTrunc fontReduce2">
											{i + 1}
										</div>
										<div className="col my-auto textTrunc fontReduce2">
											{item?.package}
											{item?.name}
										</div>
										<div className="col my-auto textTrunc fontReduce2">
											{nairaSign}{" "}
											{item?.price &&
												numberWithCommas(Number(item?.price).toFixed(2))}
											{item?.plan_amount &&
												numberWithCommas(Number(item?.plan_amount).toFixed(2))}
										</div>
									</div>
							  ))}
						<h5 className="Lexend my-3 fontReduceBig">GOtv List</h5>
						<div className="row mx-0 p-3 bland">
							<div className="col my-auto textTrunc fontReduce fw-bold Lexend">
								S/N
							</div>
							<div className="col my-auto textTrunc fontReduce fw-bold Lexend">
								Name
							</div>
							<div className="col my-auto textTrunc fontReduce fw-bold Lexend">
								Price
							</div>
						</div>
						{!Array?.isArray(cables?.cable_package?.gotv)
							? null
							: cables?.cable_package?.gotv?.map((item, i) => (
									<div
										className="row mx-0 p-3 border-bottom myCursor"
										// onClick={() => setClickedData({ ...item, type: "GOTV" })}
										key={i}>
										<div className="col my-auto textTrunc fontReduce2">
											{i + 1}
										</div>
										<div className="col my-auto textTrunc fontReduce2">
											{item?.package}
											{item?.name}
										</div>
										<div className="col my-auto textTrunc fontReduce2">
											{nairaSign}{" "}
											{item?.price &&
												numberWithCommas(Number(item?.price).toFixed(2))}
											{item?.plan_amount &&
												numberWithCommas(Number(item?.plan_amount).toFixed(2))}
										</div>
									</div>
							  ))}
						<h5 className="Lexend my-3 fontReduceBig">Startimes List</h5>
						<div className="row mx-0 p-3 bland">
							<div className="col my-auto textTrunc fontReduce fw-bold Lexend">
								S/N
							</div>
							<div className="col my-auto textTrunc fontReduce fw-bold Lexend">
								Name
							</div>
							<div className="col my-auto textTrunc fontReduce fw-bold Lexend">
								Price
							</div>
						</div>
						{!Array?.isArray(cables?.cable_package?.startimes)
							? null
							: cables?.cable_package?.startimes?.map((item, i) => (
									<div
										className="row mx-0 p-3 border-bottom myCursor"
										// onClick={() => setClickedData({ ...item, type: "STARTIMES" })}
										key={i}>
										<div className="col my-auto textTrunc fontReduce2">
											{i + 1}
										</div>
										<div className="col my-auto textTrunc fontReduce2 text-capitalize">
											{item?.package}
											{item?.name}
										</div>
										<div className="col my-auto textTrunc fontReduce2">
											{nairaSign}{" "}
											{item?.price &&
												numberWithCommas(Number(item?.price).toFixed(2))}
											{item?.plan_amount &&
												numberWithCommas(Number(item?.plan_amount).toFixed(2))}
										</div>
									</div>
							  ))}
					</>
				)}
			</Container>
			<CablesModalOption isOpen={isOpen} toggle={toggle} datum={state} />
		</div>
	);
};

export default TVSub;

export const CablesModalOption = ({ isOpen, toggle, datum }) => {
	let { cables } = useContext(GlobalState);
	let [submit, setSubmit] = useState(false);

	useEffect(() => {
		if (submit && cables?.isAdded) {
			toggle();
			setSubmit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit, cables?.isAdded]);
	return (
		<ModalComponents title="cable subscription" isOpen={isOpen} toggle={toggle}>
			<CablesOptions datum={datum} setSubmit={setSubmit} submit={submit} />
		</ModalComponents>
	);
};

export const CablesOptions = ({
	auto,
	datum,
	setActive,
	setAuto,
	data,
	setSubmit,
	submit,
}) => {
	let {
		cables,
		numberWithCommas,
		buyServices,
		nairaSign,
		wallet,
		nairaSignNeutral,
	} = useContext(GlobalState);
	let init = {
			type: "",
			productsCode: "",
			packagename: "",
			amount: "",
			user: "",
			smartCardNo: "",
			pin: "",
			dstvaddonCode: "",
		},
		[state, setState] = useState(init),
		[newState, setNewState] = useState(null),
		[loading, setLoading] = useState(null),
		textChange =
			name =>
			({ target: { value } }) => {
				setState({ ...state, [name]: value });
			},
		{ handleFetch, validateLoading } = useValidation(
			"smartCardNo",
			{ ...state, type: state?.type?.toUpperCase() },
			setNewState
		),
		[buyActive, setBuyActive] = useState(0);

	useEffect(() => {
		if (state?.type)
			if (
				state?.smartCardNo?.length === 10 ||
				state?.smartCardNo?.length === 11
			)
				handleFetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state?.smartCardNo, state?.type]);

	useEffect(() => {
		if (validateLoading) {
			// console.log({ newState });
			setState({
				...state,
				user: null,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [validateLoading]);

	useEffect(() => {
		if (datum) {
			setState({
				...state,
				...datum,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [datum]);

	useEffect(() => {
		if (data?.properties) {
			setState({
				...state,
				...data?.properties,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.properties]);

	useEffect(() => {
		if (newState) {
			console.log({ newState });
			setState({
				...state,
				user: newState?.data,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newState]);

	let handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.smartCardNo) return;
		let send = state;

		let find;
		if (Array?.isArray(cables?.cable_package?.[state?.type?.toLowerCase()])) {
			find = cables?.cable_package?.[state?.type?.toLowerCase()]?.find(
				item => item?.code === state?.productsCode
			);
			if (!find) return;
		}
		if (state?.dstvaddonCode && state?.type?.toUpperCase() === "DSTV") {
			if (Array?.isArray(cables?.cable_package?.dstvaddon)) {
				let findCode = cables?.cable_package?.dstvaddon?.find(
					it => it?.code === state?.dstvaddonCode
				);
				if (findCode) send.dstvaddonCode = findCode?.code;
				else delete send.dstvaddonCode;
			} else delete send.dstvaddonCode;
		} else {
			delete send.dstvaddonCode;
		}
		send = {
			...send,
			packagename: find?.name,
			type: state?.type?.toUpperCase(),
			amount: state?.amount || find?.price,
		};
		console.log({ send, state });
		setLoading(true);
		await buyServices("cables", send);
		setLoading(false);
		setSubmit(true);
	};

	useEffect(() => {
		if (state?.pin && state?.pin?.length === 4)
			if (auto) {
				setAuto({ ...data, properties: state });
				setActive(3);
			} else handleSubmit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state?.pin, auto]);

	useEffect(() => {
		if (submit && cables?.isAdded) {
			setState(init);
			setBuyActive(0);
			setSubmit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit, cables?.isAdded]);

	return (
		<div className="downH2 d-flex">
			{buyActive === 2 ? (
				<TransactionPinBox
					state={state}
					setState={setState}
					handleSubmit={handleSubmit}
					loading={loading || validateLoading}
				/>
			) : buyActive === 1 ? (
				<>
					<div className="w-100">
						<CablesDetails state={state} />
						{!auto && (
							<>
								<div className="d-flex justify-content-end">
									<Buttons
										title={"back"}
										css="btn-outline-primary1 text-capitalize"
										width={"w-auto"}
										onClick={() => {
											setBuyActive(0);
										}}
									/>
								</div>
							</>
						)}
						<Buttons
							title={
								validateLoading
									? "Validating..."
									: auto || wallet?.balance?.wallet_pin
									? "next"
									: "pay"
							}
							css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto"
							width={"w-50 w50"}
							onClick={
								wallet?.balance?.wallet_pin
									? () => {
											if (state?.type !== "STARTIMES") if (!state?.user) return;
											setBuyActive(2);
									  }
									: auto
									? () => {
											setAuto({ ...data, properties: state });
											setActive(3);
									  }
									: () => {
											if (state?.type !== "STARTIMES") if (!state?.user) return;
											handleSubmit();
									  }
							}
							loading={loading || validateLoading}
							style={{ borderRadius: "30px" }}
						/>
					</div>
				</>
			) : (
				<form className="w-100" onSubmit={handleSubmit}>
					<div className="mb-4">
						<label htmlFor="Type">Select cable</label>
						{/* <select
							name="type"
							id="type"
							value={state?.type}
							onChange={textChange("type")}
							className="form-control form-select py-3 rounded20">
							<option value="">Select Type</option>
							{cables?.cable_direct?.map((item, i) => (
								<option value={item?.name} key={i}>
									{item?.name}
								</option>
							))}
						</select> */}
						<CablesList
							state={state?.type}
							setState={i => {
								setState({ ...state, type: i });
							}}
						/>
					</div>
					{state?.type &&
						Array?.isArray(
							cables?.cable_package?.[state?.type?.toLowerCase()]
						) && (
							<div className="mb-4">
								<label htmlFor="Package">Package</label>
								<select
									name="productsCode"
									id="productsCode"
									value={state?.productsCode}
									onChange={textChange("productsCode")}
									className="form-control form-select py-3 rounded20">
									<option value="">Select package</option>
									{Array?.isArray(
										cables?.cable_package?.[state?.type?.toLowerCase()]
									) &&
										cables?.cable_package?.[state?.type?.toLowerCase()]?.map(
											(item, i) => (
												<option
													value={
														item?.cableplan_id ? item?.cableplan_id : item?.code
													}
													key={i}>
													{item?.package ? item?.package : item?.name}{" "}
													{nairaSign}
													{numberWithCommas(
														item?.plan_amount ? item?.plan_amount : item?.price
													)}
													{item?.month && item?.month > 1 && (
														<> ({item?.month} months)</>
													)}
												</option>
											)
										)}
								</select>
							</div>
						)}
					<div className="mb-4">
						<label htmlFor="telephone">Smart card number</label>
						{/* <input
							type={"tel"}
							value={state?.smartCardNo}
							onChange={textChange("smartCardNo")}
							placeholder="08012345678"
							name="smartCardNo"
							className="form-control py-3"
							maxLength={10}
						/> */}
						<NumericFormat
							className="form-control py-3"
							value={state?.smartCardNo}
							allowLeadingZeros={true}
							allowNegative={false}
							displayType="input"
							name="smartCardNo"
							onValueChange={val =>
								setState({ ...state, smartCardNo: val?.value })
							}
							maxLength={state?.type?.toLowerCase() === "startimes" ? 11 : 10}
							decimalScale={0}
							inputMode="tel"
							renderText={(value, props) => <span {...props}>{value}</span>}
						/>
					</div>
					{!Array?.isArray(
						cables?.cable_package?.[state?.type?.toLowerCase()]
					) && (
						<div className="mb-4">
							<label htmlFor="telephone">Amount</label>
							{/* <input
								type={"number"}
								value={state?.amount}
								onChange={textChange("amount")}
								placeholder="Amount"
								className="form-control py-3"
								min={0}
							/> */}
							<NumericFormat
								prefix={`${nairaSignNeutral} `}
								className="form-control py-3"
								value={state?.amount}
								placeholder="500"
								displayType="input"
								thousandSeparator={true}
								onValueChange={val =>
									setState({ ...state, amount: val?.floatValue })
								}
								min={0}
								inputMode="decimal"
								renderText={(value, props) => <span {...props}>{value}</span>}
								allowNegative={false}
							/>
						</div>
					)}
					{state?.type?.toLowerCase() === "dstv" &&
						Array?.isArray(cables?.cable_package?.dstvaddon) && (
							<>
								<div className="mb-4">
									<label htmlFor="Package">Addon Package (optional)</label>
									<select
										name="dstvaddonCode"
										id="dstvaddonCode"
										value={state?.dstvaddonCode}
										onChange={textChange("dstvaddonCode")}
										className="form-control form-select py-3 rounded20 text-capitalize">
										<option value="">Select package</option>
										{Array?.isArray(cables?.cable_package?.dstvaddon) &&
											cables?.cable_package?.dstvaddon?.map((item, i) => (
												<option
													value={item?.cableplan_id || item?.code}
													key={i}>
													{item?.package || item?.name} {nairaSign}
													{numberWithCommas(item?.plan_amount || item?.price)}
													{item?.month && item?.month > 1 && (
														<> ({item?.month} months)</>
													)}
												</option>
											))}
									</select>
								</div>
							</>
						)}
					<Buttons
						title={validateLoading ? "Validating..." : "proceed"}
						css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto"
						width={"w-50 w50"}
						onClick={() => {
							if (!state?.smartCardNo) return;
							if (state?.smartCardNo?.length !== 10)
								return returnErrors({
									error: [
										{
											msg: `Smart Card Number should be 10 digits`,
											param: "smartCardno",
										},
									],
								});
							setBuyActive(1);
						}}
						style={{ borderRadius: "30px" }}
						loading={validateLoading}
					/>
				</form>
			)}
		</div>
	);
};

export const CablesList = ({ state, setState }) => {
	let { cables } = useContext(GlobalState);
	return (
		<div className="row mx-0">
			{cables?.cable_direct?.map((item, i) => (
				<div
					className="col p-2"
					onClick={setState ? () => setState(item?.name) : () => {}}
					key={i}>
					<div
						style={{
							height: "4.5rem",
							width: "4.5rem",
						}}
						className={`rounded d-flex myCursor ${
							state === item?.name
								? "borderColor borderColor2 list-group-item-primary"
								: ""
						}`}>
						<img
							src={item?.image?.url}
							alt={item?.image?.name}
							className="img-fluid imgFluid tw-h-96 m-auto"
						/>
					</div>
				</div>
			))}
		</div>
	);
};

export const CablesDetails = ({ state }) => {
	let { cables, numberWithCommas, nairaSign, settings, auth } =
		useContext(GlobalState);
	let [stateData, setStateData] = useState(null),
		[commission, setCommission] = useState(0),
		[mainAmount, setMainAmount] = useState(0);
	useEffect(() => {
		setStateData(settings?.settings);
	}, [settings?.settings]);

	useEffect(() => {
		let amount = !Array?.isArray(
			cables?.cable_package?.[state?.type?.toLowerCase()]
		)
			? state?.amount
			: cables?.cable_package?.[state?.type?.toLowerCase()]?.find(
					item => item?.code?.toString() === state?.productsCode?.toString()
			  )
			? Number(
					cables?.cable_package?.[state?.type?.toLowerCase()]?.find(
						item => item?.code?.toString() === state?.productsCode?.toString()
					)?.price
			  ).toFixed(2)
			: 0;
		if (state?.type === "DSTV" && state?.dstvaddonCode) {
			if (Array?.isArray(cables?.cable_package?.dstvaddon)) {
				let findCode = cables?.cable_package?.dstvaddon?.find(
					it => it?.code === state?.dstvaddonCode
				);
				if (findCode) amount = Number(amount) + Number(findCode?.price);
			}
		}
		setMainAmount(amount);
		if (amount && stateData) {
			let com = stateData?.cablesCommission;
			if (auth?.user?.privilege === "reseller") {
				com =
					stateData?.cablesResellerCommission || stateData?.cablesCommission;
			}
			if (auth?.user?.privilege === "agent") {
				com = stateData?.cablesAgentCommission || stateData?.cablesCommission;
			}

			setCommission(Number((com / 100) * amount));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		cables?.cable_package,
		state?.amount,
		state?.numberOfPin,
		state?.productsCode,
		state?.type,
		state?.dstvaddonCode,
		stateData,
	]);

	return (
		<>
			<p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
				<span>Type: </span>
				<span className="fontInherit Lexend">{state?.type}</span>{" "}
			</p>
			{Array?.isArray(cables?.cable_package?.[state?.type?.toLowerCase()]) && (
				<p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
					<span>Package: </span>
					<span className="fontInherit Lexend">
						{state?.type &&
							cables?.cable_package?.[state?.type?.toLowerCase()]?.find(
								item =>
									item?.code?.toString() === state?.productsCode?.toString()
							)?.name}
					</span>{" "}
				</p>
			)}
			<p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
				<span>Smart Card Number: </span>
				<span
					className="fontInherit Lexend force-d-flex myCursor"
					onClick={
						state?.smartCardNo
							? () => {
									navigator.clipboard.writeText(state?.smartCardNo).then(
										() => {
											toast.info("Copied", { autoClose: 2000 });
										},
										err => {
											toast.warn(`Could not copy: ${err}`, {
												autoClose: 2000,
											});
										}
									);
							  }
							: null
					}>
					{state?.smartCardNo}
					<BiCopy />
				</span>{" "}
			</p>
			<p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
				<span>Amount: </span>
				<span className="fontInherit Lexend">
					{nairaSign}{" "}
					{!Array?.isArray(cables?.cable_package?.[state?.type?.toLowerCase()])
						? numberWithCommas(state?.amount)
						: cables?.cable_package?.[state?.type?.toLowerCase()]?.find(
								item =>
									item?.code?.toString() === state?.productsCode?.toString()
						  )
						? numberWithCommas(
								Number(
									cables?.cable_package?.[state?.type?.toLowerCase()]?.find(
										item =>
											item?.code?.toString() === state?.productsCode?.toString()
									)?.price
								).toFixed(2)
						  )
						: 0}
				</span>{" "}
			</p>
			{state?.type?.toLowerCase() === "dstv" && state?.dstvaddonCode && (
				<>
					<p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
						<span>Addon Amount: </span>
						<span className="fontInherit Lexend">
							{nairaSign}{" "}
							{Array?.isArray(cables?.cable_package?.dstvaddon)
								? numberWithCommas(
										Number(
											cables?.cable_package?.dstvaddon?.find(
												item =>
													item?.code?.toString() ===
													state?.dstvaddonCode?.toString()
											)?.price
										).toFixed(2)
								  )
								: 0}
						</span>{" "}
					</p>
					<p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
						<span>Total Amount: </span>
						<span className="fontInherit Lexend">
							{nairaSign} {numberWithCommas(Number(mainAmount || 0).toFixed(2))}
						</span>{" "}
					</p>
				</>
			)}
			<p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
				<span>Commission: </span>
				<span className="fontInherit Lexend">
					{nairaSign} {numberWithCommas(Number(commission || 0).toFixed(2))}
				</span>{" "}
			</p>
			<p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
				<span>Customer name: </span>
				<span className="fontInherit Lexend">
					{state?.user?.customerName}
				</span>{" "}
			</p>
			{/* <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
				<span>Customer number: </span>
				<span className="fontInherit Lexend">
					{state?.user?.customerNumber}
				</span>{" "}
			</p> */}
		</>
	);
};

const TVSubHistory = ({ setThisData, thisData }) => {
	const { cables, getServicesHistory, getReload } = useContext(GlobalState);
	let [state, setState] = useState(null);

	let [loading, setLoading] = useState(false),
		[search, setSearch] = useState("");

	useEffect(() => {
		getServicesHistory("cables");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (search) {
			document.getElementById("Search").addEventListener("search", () => {
				getReload();
			});
			let handleSubmit = async () => {
				if (!search) return;

				await getServicesHistory("cables", {
					search,
				});
			};
			handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	useEffect(() => {
		if (cables.isFound) {
			setState(cables.mainSearch);
		} else setState(cables.cable);
	}, [cables.cable, cables.isFound, cables.mainSearch]);

	useEffect(() => {
		getReload();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let handleLoadMore = async () => {
		setLoading(true);

		if (search) {
			await getServicesHistory("cables", {
				page: Number(cables?.paginate?.nextPage),
				// limit: Number(cables?.paginate?.nextPage * cables?.paginate?.limit),
				search,
			});
		} else {
			await getServicesHistory("cables", {
				page: Number(cables?.paginate?.nextPage),
				// limit: Number(cables?.paginate?.nextPage * cables?.paginate?.limit),
			});
		}
		setLoading(false);
	};

	if (!state) return;

	return (
		<div className="py-5">
			<div className="w-50 w50 mb-3">
				<input
					type="search"
					name="search"
					id="Search"
					className="form-control w-100 py-3 borderColor2"
					placeholder="Type here to search"
					value={search}
					onChange={e => setSearch(e.target.value)}
				/>
			</div>
			<NewPaginate
				state={state}
				setState={setState}
				setThisData={setThisData}
				type={"cables"}
				criteria={
					{
						// id: params?.step,
					}
				}
			/>
			<TransactionDetails
				thisData={thisData}
				setThisData={setThisData}
				type={"cables"}
				criteria={
					{
						// id: params?.step,
					}
				}
			/>
			<BottomTab
				state={state}
				paginate={search ? cables?.search_paginate : cables?.paginate}
			/>
			<LoadMore
				next={search ? cables?.search_paginate?.next : cables?.paginate?.next}
				handleLoadMore={handleLoadMore}
				loading={loading}
			/>
		</div>
	);
};
