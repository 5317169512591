import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { GlobalState } from "../Data/Context";
// import user from "../Assets/avatar3.png";
import { toast } from "react-toastify";
// import { BsImage } from "react-icons/bs";
import { Buttons, EyeToggle, OtpComponent } from "../Utils";
import { ModalComponents } from "./DefaultHeader";
import { useNavigate, useParams } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { TransactionPinBox } from "./Products/AutoBuy";
import axios from "axios";

const MainSettings = () => {
	const { auth, updateUser, setStateName, getStates, getReferrals, referral } =
		useContext(GlobalState);

	useEffect(() => {
		setStateName("settings");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getReferrals();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
		getStates();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let [state, setState] = useState(null),
		[logo, setLogo] = useState(false),
		[isOpen, setIsOpen] = useState(false),
		[loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		toggle = () => {
			setIsOpen(!isOpen);
		},
		[active, setActive] = useState(0);

	useEffect(() => {
		setState(auth?.user);
	}, [auth?.user]);

	let handleChangeImage = e => {
			const file = e.target.files[0];
			let err = "";

			if (!file) return (err = `File, ${file?.name} does not exist`);
			if (!file.type.includes("image"))
				return (err = `File, ${file?.name} format not supported`);

			if (err) {
				return toast.error(err);
			} else {
				setLogo(file);
			}
		},
		handleSubmit = type => async e => {
			if (e) e.preventDefault();
			if (type === "profile-image") {
				if (!logo) return toast.info("Image required", { auth: 10000 });
				setLoading(true);
				await updateUser({ logo }, "profile-image");
				setLoading(false);
			} else {
				if (!state?.telephone)
					return toast.info("Bio required", { auth: 10000 });
				setLoading(true);
				await updateUser(state);
				setLoading(false);
			}
			setSubmit(true);
		};

	let textChange =
		name =>
		({ target: { value } }) => {
			setState({ ...state, [name]: value });
		};

	useEffect(() => {
		if (auth?.isUpdated && submit) {
			setIsOpen(false);
			setSubmit(false);
		}
	}, [auth?.isUpdated, submit]);

	// if (!state) return;

	return (
		<div className="py-4 bg-white aboutScreen">
			<Container className="py-5">
				<div className="d-flex justify-content-between align-items-center mb-3 px-md-4 px-2">
					<div>
						<h4 className="Lexend fw-600 fontReduceBig">My Account Settings</h4>
						<div>
							<button
								onClick={() => setActive(0)}
								className={`btn ${
									active !== 0 ? "btn-light" : "btn-outline-primary1"
								}
								text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-2 fontReduce2 text-dark`}>
								profile
							</button>
							<button
								onClick={() => setActive(1)}
								className={`btn ${
									active !== 1 ? "btn-light" : "btn-outline-primary1"
								}
								text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-2 fontReduce2 text-dark`}>
								Cashback
							</button>
						</div>
					</div>
					<div className="d-md-flex align-items-center">
						{/* <img
							src={auth?.user?.avatar?.url || user}
							alt={`img`}
							style={{
								height: "7rem",
								width: "7rem",
								objectFit: "cover",
								objectPosition: "center 15%",
							}}
							className="rounded-circle img-fluid mx-3 d-block d-md-flex"
						/> */}
						{/* <AvatarImg
							user={auth?.user}
							style={{
								height: "7rem",
								width: "7rem",
								objectFit: "cover",
								objectPosition: "center 15%",
							}}
						/> */}
						<div className="">
							<h5 className="Lexend">
								{auth?.user?.firstName} {auth?.user?.lastName}
							</h5>
							<small className="text-uppercase">
								<span className="font-bold">Account type: </span>
								{auth?.user?.privilege}
							</small>
							{/* {referral?.general_paginate?.total && ( */}
							<small className="text-capitalize d-block">
								<span className="font-bold">
									Referral
									<span className="text-lowercase">(s)</span>:{" "}
								</span>
								{referral?.general_paginate?.total}
							</small>
							{/* )} */}
						</div>
					</div>
				</div>
				{active === 1 ? (
					<GeneralSettings />
				) : (
					<ProfileSetup
						state={state}
						textChange={textChange}
						handleChangeImage={handleChangeImage}
						handleSubmit={handleSubmit}
						toggle={toggle}
						logo={logo}
						loading={loading}
						isOpen={isOpen}
						setState={setState}
					/>
				)}
			</Container>
		</div>
	);
};

export default MainSettings;

const ProfileSetup = ({
	state,
	textChange,
	isOpen,
	toggle,
	logo,
	handleChangeImage,
	handleSubmit,
	loading,
	setState,
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { auth, usecase } = useContext(GlobalState);

	let [isRequest, setIsRequest] = useState(""),
		closeRequest = () => {
			setIsRequest("");
		};

	return (
		<>
			<div className="px-md-4 px-2 mb-5">
				<h5 className="Lexend fw-600 fontReduceBig">Profile</h5>
				<p className="fontReduce2">Update personal details here</p>
				{/* <h5 className="mt-3 Lexend fw-600 fontReduceBig">Photos</h5>
				<div className="d-flex align-items-center">
					<img
						src={state?.avatar?.url ? state?.avatar?.url : user}
						alt={`img`}
						style={{
							height: "4rem",
							width: "4rem",
							objectFit: "cover",
							objectPosition: "center 15%",
						}}
						className="rounded-circle img-fluid mx-3"
					/>
					<div className="d-flex align-items-center">
						<button
							onClick={toggle}
							className={`btn btn-light text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-2 fontReduce2 text-dark`}>
							change
						</button>
						<ModalComponents
							isOpen={isOpen}
							back={toggle}
							title="Update profile image">
							<div className="d-flex">
								<div className="mx-auto position-relative">
									<img
										src={
											logo
												? URL.createObjectURL(logo)
												: state?.avatar?.url
												? state?.avatar.url
												: user
										}
										alt={state?.firstName}
										style={{
											height: "15rem",
											width: "15rem",
										}}
										className="rounded-circle img-fluid mx-auto"
									/>
									<div className="file_upload d-flex myCursor mt-auto ms-auto justify-content-end">
										<BsImage
											size={22}
											title="Upload image"
											className="mx-2 myCursor statusIcon"
										/>
										<input
											title="Upload file"
											type="file"
											name="file"
											id="file"
											multiple
											className="myCursor"
											accept="image/*"
											onChange={handleChangeImage}
										/>
									</div>
									{logo && (
										<Buttons
											onClick={handleSubmit("profile-image")}
											loading={logo && loading}
											css="btn btn-primary1 text-capitalize py-3 my-4"
											title={"Update profile image"}
										/>
									)}
								</div>
							</div>
						</ModalComponents>
					</div>
				</div> */}
			</div>
			<form onSubmit={handleSubmit("update")}>
				<ProfileForm
					state={state}
					textChange={textChange}
					setState={setState}
				/>
				<div className="d-flex justify-content-end my-3">
					<div className="d-flex align-items-center">
						<Buttons
							onClick={handleSubmit("update")}
							loading={!logo && loading}
							width=""
							css="btn-primary1 text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-2 fontReduce2 text-white"
							title={"save"}
						/>
						<button
							className={`btn btn-outline-primary1 text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-2 fontReduce2 text-primary1`}>
							cancel
						</button>
					</div>
				</div>
			</form>
			{!auth?.user?.isAdmin && (
				<div className="py-3 py-md-5">
					{auth?.user?.privilege !== "reseller" &&
						usecase?.usecase?.resellerUpgrade === "enable" && (
							<button
								onClick={() => setIsRequest("reseller")}
								className="btn btn-primary1
								text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-3 fontReduce2 text-white">
								become a reseller
							</button>
						)}
					{auth?.user?.privilege !== "agent" &&
						usecase?.usecase?.agentUpgrade === "enable" && (
							<button
								onClick={() => setIsRequest("agent")}
								className="btn btn-primary1
								text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-3 fontReduce2 text-white">
								become an agent
							</button>
						)}
					{/* {auth?.user?.privilege !== "topuser" &&
						usecase?.usecase?.topuserUpgrade === "enable" && (
							<button
								onClick={() => setIsRequest("topuser")}
								className="btn btn-primary1
								text-capitalize px-md-4 px-2 mx-1 mx-md-3 py-1 py-md-3 fontReduce2 text-white">
								become a topuser
							</button>
						)} */}
				</div>
			)}
			<WalletPinBox />
			<PasswordBox />
			<DeleteAccountBox />
			<BecomeAgent isOpen={isRequest} back={closeRequest} />
		</>
	);
};

const BecomeAgent = ({ isOpen, back }) => {
	let {
		usecase,
		upgrade,
		numberWithCommas,
		manageUpgrade,
		returnErrors,
		nairaSignNeutral,
		countryState,
	} = useContext(GlobalState);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	let init = {
			privilege: isOpen,
			websiteURL: "",
			businessName: "",
			stateOfResidence: "",
			dateOfBirth: "",
			ninNumber: "",
		},
		[state, setState] = useState(init),
		textChange =
			name =>
			({ target: { value } }) => {
				setState({ ...state, [name]: value });
			},
		[loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		handleSubmit = async () => {
			let errArr = [];

			if (!state?.businessName)
				errArr.push({
					msg: "Business name is required",
					param: "businessName",
				});
			if (!state?.stateOfResidence)
				errArr.push({
					msg: "State of residence is required",
					param: "stateOfResidence",
				});
			if (!state?.ninNumber)
				errArr.push({ msg: "NIN number is required", param: "ninNumber" });
			if (errArr?.length > 0)
				return returnErrors({
					error: errArr,
				});

			setLoading(true);
			await manageUpgrade({ ...state, privilege: isOpen });
			setLoading(false);
			setSubmit(true);
		};

	useEffect(() => {
		if (submit && upgrade?.isAdded) {
			back();
			setSubmit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit, upgrade]);

	return (
		<>
			<ModalComponents
				isOpen={isOpen ? true : false}
				back={back}
				title={`Become ${isOpen === "agent" ? "an" : "a"} ${isOpen}`}>
				<form onSubmit={handleSubmit}>
					<div className="mb-3">
						<label htmlFor="businessName">Business Name</label>
						<input
							type="text"
							name="businessName"
							value={state?.businessName}
							onChange={textChange("businessName")}
							placeholder="Your Business Name"
							className="form-control py-3"
						/>
					</div>
					{isOpen === "agent" && (
						<div className="mb-3">
							<label htmlFor="websiteURL">Website URL</label>
							<input
								type="url"
								name="websiteURL"
								value={state?.websiteURL}
								onChange={textChange("websiteURL")}
								placeholder="Your Website URL"
								className="form-control py-3"
							/>
						</div>
					)}
					<div className="mb-3">
						<label htmlFor="ninNumber">NIN</label>
						{/* <input
							type="text"
							name="ninNumber"
							value={state?.ninNumber}
							onChange={textChange("ninNumber")}
							placeholder="Your NIN number"
							className="form-control py-3"
						/> */}
						<NumericFormat
							className="form-control py-3"
							value={state.ninNumber}
							allowLeadingZeros={true}
							allowNegative={false}
							displayType="input"
							name="ninNumber"
							onValueChange={val =>
								setState({
									...state,
									ninNumber: val?.value.replace(/[^0-9]*/g, ""),
								})
							}
							maxLength={11}
							inputMode="tel"
							renderText={(value, props) => <span {...props}>{value}</span>}
							decimalScale={0}
							pattern="[0-9]"
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="stateOfResidence">State of Residence</label>
						{/* <input
							type="text"
							name="stateOfResidence"
							value={state?.stateOfResidence}
							onChange={textChange("stateOfResidence")}
							placeholder="Your State of Residence"
							className="form-control py-3"
						/> */}
						<select
							value={state?.stateOfResidence}
							onChange={textChange("stateOfResidence")}
							name="stateOfResidence"
							placeholder="Lagos State"
							className="form-control py-3">
							<option value="">Select State of Residence</option>
							{countryState?.map((it, i) => (
								<option value={it?.name} key={i}>
									{it?.name}: {it?.capital}
								</option>
							))}
						</select>
					</div>
					{/* <div className="mb-3">
						<label htmlFor="dateOfBirth">Date of birth</label>
						<input
							type="date"
							name="dateOfBirth"
							value={state?.dateOfBirth}
							onChange={textChange("dateOfBirth")}
							placeholder="Your Date of Birth"
							className="form-control py-3"
							max={moment().format("YYYY-MM-DD")}
						/>
					</div> */}
					<Buttons
						title={`Proceed ${nairaSignNeutral} ${
							isOpen === "reseller"
								? numberWithCommas(
										Number(usecase?.usecase?.resellerUpgradeFee).toFixed()
								  )
								: isOpen === "agent"
								? numberWithCommas(
										Number(usecase?.usecase?.agentUpgradeFee).toFixed()
								  )
								: numberWithCommas(
										Number(usecase?.usecase?.topuserUpgradeFee).toFixed(2)
								  )
						} `}
						css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
						width={"w-50"}
						style={{ borderRadius: "30px" }}
						loading={loading}
						onClick={handleSubmit}
					/>
				</form>
			</ModalComponents>
		</>
	);
};

const GeneralSettings = () => {
	const { settings, converter, auth, converterServices } =
		useContext(GlobalState);

	let [stateData, setStateData] = useState(null),
		[submit, setSubmit] = useState(false);

	useEffect(() => {
		setStateData(settings?.settings);
	}, [settings?.settings]);

	useEffect(() => {
		window.scrollTo(0, 0);
		converterServices("get", "converter-number");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (submit && settings?.isUpdated) {
			setSubmit(false);
		}
	}, [settings?.isUpdated, submit]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Container className="px-lg-5  pt-3 pt-lg-0">
			<div className="mb-5">
				<h5 className="Lexend fw-600 fontReduceBig">My Commission List</h5>
			</div>
			<div className="row mx-0 g-3 g-md-4">
				<p className="fontReduce col-md-6">
					MTN Airtime Commission:{" "}
					{auth?.user?.privilege === "agent"
						? stateData?.mtnAgentCommission || stateData?.mtnCommission
						: auth?.user?.privilege === "reseller"
						? stateData?.mtnResellerCommission || stateData?.mtnCommission
						: stateData?.mtnCommission}
					%
				</p>
				<p className="fontReduce col-md-6">
					GLO Airtime Commission:{" "}
					{auth?.user?.privilege === "agent"
						? stateData?.gloAgentCommission || stateData?.gloCommission
						: auth?.user?.privilege === "reseller"
						? stateData?.gloResellerCommission || stateData?.gloCommission
						: stateData?.gloCommission}
					%
				</p>
				<p className="fontReduce col-md-6">
					AIRTEL Airtime Commission:{" "}
					{auth?.user?.privilege === "agent"
						? stateData?.airtelAgentCommission || stateData?.airtelCommission
						: auth?.user?.privilege === "reseller"
						? stateData?.airtelResellerCommission || stateData?.airtelCommission
						: stateData?.airtelCommission}
					%
				</p>
				<p className="fontReduce col-md-6">
					9MOBILE Airtime Commission:{" "}
					{auth?.user?.privilege === "agent"
						? stateData?.mobile9AgentCommission || stateData?.mobile9Commission
						: auth?.user?.privilege === "reseller"
						? stateData?.mobile9ResellerCommission ||
						  stateData?.mobile9Commission
						: stateData?.mobile9Commission}
					%
				</p>
				<p className="fontReduce col-md-6">
					Electricity Commission:
					{auth?.user?.privilege === "agent"
						? stateData?.electricityAgentCommission ||
						  stateData?.electricityCommission
						: auth?.user?.privilege === "reseller"
						? stateData?.electricityResellerCommission ||
						  stateData?.electricityCommission
						: stateData?.electricityCommission}
					%
				</p>
				<p className="fontReduce col-md-6">
					Cables Commission:{" "}
					{auth?.user?.privilege === "agent"
						? stateData?.cablesAgentCommission || stateData?.cablesCommission
						: auth?.user?.privilege === "reseller"
						? stateData?.cablesResellerCommission || stateData?.cablesCommission
						: stateData?.cablesCommission}
					%
				</p>
				{/* <p className="fontReduce col-md-6">
					Education Commission:{" "}
					{auth?.user?.privilege === "agent"
						? stateData?.educationAgentCommission ||
						  stateData?.educationCommission
						: auth?.user?.privilege === "reseller"
						? stateData?.educationResellerCommission ||
						  stateData?.educationCommission
						: stateData?.educationCommission}
					%
				</p> */}
				{converter?.numbers?.map((item, i) => (
					<p key={i} className="fontReduce col-md-6">
						{item?.network} Airtime to Cash Return Percentage:{" "}
						{item?.percentage}%
					</p>
				))}
				{/* <p className="fontReduce col-md-6">
					Airtime to cash return: {stateData?.airtimeToCashCommission}%
				</p> */}
			</div>
		</Container>
	);
};

export const ProfileForm = ({ state, textChange, setState }) => {
	let params = useParams(),
		{ auth, usecase, nairaSignNeutral, nairaSign } = useContext(GlobalState),
		[typePass, setTypePass] = useState(false),
		[typePass2, setTypePass2] = useState(false);
	return (
		<div className="row mx-0 g-3 g-md-5">
			<div className="col-md-6">
				<label className="dmMonoFont text-uppercase" htmlFor="firstName">
					First Name
				</label>
				<input
					type="text"
					className="form-control py-3 dmMonoFont"
					name="firstName"
					value={state?.firstName}
					onChange={textChange("firstName")}
					readOnly={params?.page === "users"}
				/>
			</div>
			<div className="col-md-6">
				<label className="dmMonoFont text-uppercase" htmlFor="lastName">
					Last Name
				</label>
				<input
					type="text"
					className="form-control py-3 dmMonoFont"
					name="lastName"
					value={state?.lastName}
					onChange={textChange("lastName")}
					readOnly={params?.page === "users"}
				/>
			</div>
			<div className="col-md-6">
				<label className="dmMonoFont text-uppercase" htmlFor="telephone">
					Telephone
				</label>
				<input
					type="tel"
					className="form-control py-3 dmMonoFont"
					name="telephone"
					value={state?.telephone}
					onChange={textChange("telephone")}
					placeholder="Telephone"
					readOnly
				/>
			</div>
			<div className="col-md-6">
				<label className="dmMonoFont text-uppercase" htmlFor="email">
					Email
				</label>
				<input
					type="email"
					className="form-control py-3 dmMonoFont"
					name="email"
					value={state?.email}
					onChange={textChange("email")}
					placeholder="Email"
					readOnly
				/>
			</div>
			<div className="col-md-6">
				<label htmlFor="ninNumber">
					NIN{" "}
					{!auth?.user?.ninNumber && (
						<>
							({nairaSign}
							{usecase?.usecase?.ninCharge})
						</>
					)}
				</label>
				{/* <input
							type="text"
							name="ninNumber"
							value={state?.ninNumber}
							onChange={textChange("ninNumber")}
							placeholder="Your NIN number"
							className="form-control py-3"
						/> */}
				{/* <NumericFormat
					className="form-control py-3 dmMonoFont"
					value={state?.ninNumber}
					allowLeadingZeros={true}
					allowNegative={false}
					displayType={auth?.user?.ninNumber ? "text" : "input"}
					name="ninNumber"
					
					maxLength={11}
					inputMode="tel"
					renderText={(value, props) => (
						<span {...props}>
							{auth?.user?.ninNumber ? "**********" : value}
						</span>
					)}
					decimalScale={0}
					pattern="[0-9]"
					readOnly={auth?.user?.ninNumber}
					placeholder={`You will be charged ${nairaSignNeutral}${usecase?.usecase?.ninCharge}`}
				/> */}
				<div className="tw-relative">
					<div className="tw-flex tw-h-full tw-w-full tw-items-center">
						<input
							readOnly={auth?.user?.ninNumber}
							type={typePass ? "tel" : "password"}
							maxLength={11}
							required
							style={{
								border: "none !important",
							}}
							name="ninNumber"
							className="form-control py-3 dmMonoFont"
							value={state?.ninNumber}
							onChange={textChange("ninNumber")}
							placeholder={`You will be charged ${nairaSignNeutral}${usecase?.usecase?.ninCharge}`}
						/>
						{!auth?.user?.ninNumber && (
							<div className="tw-absolute tw-top-4 tw-right-4">
								<EyeToggle typePass={typePass} setTypePass={setTypePass} />
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="col-md-6">
				<label htmlFor="ninNumber">
					BVN{" "}
					{!auth?.user?.bvnNumber && (
						<>
							({nairaSign}
							{usecase?.usecase?.bvnCharge})
						</>
					)}
				</label>
				{/* <input
							type="text"
							name="ninNumber"
							value={state?.ninNumber}
							onChange={textChange("ninNumber")}
							placeholder="Your NIN number"
							className="form-control py-3"
						/> */}
				{/* <NumericFormat
					className="form-control py-3 dmMonoFont"
					value={state?.bvnNumber}
					allowLeadingZeros={true}
					allowNegative={false}
					displayType={auth?.user?.bvnNumber ? "text" : "input"}
					name="bvnNumber"
					
					maxLength={11}
					inputMode="tel"
					renderText={(value, props) => (
						<span {...props}>
							{auth?.user?.bvnNumber ? "**********" : value}
						</span>
					)}
					decimalScale={0}
					pattern="[0-9]"
					readOnly={auth?.user?.bvnNumber}
					placeholder={`You will be charged ${nairaSignNeutral}${usecase?.usecase?.bvnCharge}`}
				/> */}
				<div className="tw-relative">
					<div className="tw-flex tw-h-full tw-w-full tw-items-center">
						<input
							readOnly={auth?.user?.bvnNumber}
							type={typePass2 ? "tel" : "password"}
							maxLength={11}
							required
							style={{
								border: "none !important",
							}}
							name="bvnNumber"
							className="form-control py-3 dmMonoFont"
							value={state?.bvnNumber}
							onChange={textChange("bvnNumber")}
							placeholder={`You will be charged ${nairaSignNeutral}${usecase?.usecase?.bvnCharge}`}
						/>
						{!auth?.user?.bvnNumber && (
							<div className="tw-absolute tw-top-4 tw-right-4">
								<EyeToggle typePass={typePass2} setTypePass={setTypePass2} />
							</div>
						)}
					</div>
				</div>
			</div>
			{/* <div className="col-md-6">
				<label className="dmMonoFont text-uppercase" htmlFor="bio">
					Bio
				</label>
				<textarea
					style={{
						height: "10rem",
						resize: "none",
					}}
					className="form-control py-3 dmMonoFont"
					name="bio"
					placeholder="Brief description"
					value={state?.bio}
					onChange={textChange("bio")}
					readOnly={params?.page === "users"}
				/>
			</div> */}
		</div>
	);
};

export const PasswordBox = () => {
	let { updatePassword, auth } = useContext(GlobalState);
	let init = {
			oldPassword: "",
			newPassword: "",
		},
		[state, setState] = useState(init),
		[loading, setLoading] = useState(false),
		[typePass, setTypePass] = useState(false),
		[submit, setSubmit] = useState(false),
		textChange =
			name =>
			({ target: { value } }) => {
				setState({ ...state, [name]: value });
			},
		handleSubmit = async e => {
			e.preventDefault();
			if (!state?.oldPassword || !state?.newPassword) return;
			setLoading(true);
			await updatePassword(state);
			setLoading(false);
			setSubmit(true);
		};

	useEffect(() => {
		if (submit && auth?.isPassword) {
			setState(init);
			setSubmit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit, auth?.isPassword]);

	return (
		<form onSubmit={handleSubmit} className="py-4 py-md-5 border-bottom">
			<h6 className="Lexend fw-600 fontReduceBig text-uppercase mt-3 mt-md-5">
				change password
			</h6>
			<small className="">
				Please enter your current password to change your password.
			</small>
			<div className="row mx-0 g-5 col-md-8 mt-3">
				<div className="col-md-6">
					<label className="dmMonoFont text-uppercase" htmlFor="password">
						old Password
					</label>
					{/* <input
						type="password"
						className="form-control py-3 dmMonoFont"
						name="password"
						placeholder="Old Password"
						value={state?.oldPassword}
						onChange={textChange("oldPassword")}
					/> */}
					<div className="tw-relative">
						<div className="tw-flex tw-h-full tw-w-full tw-items-center">
							<input
								type={typePass ? "text" : "password"}
								maxLength={11}
								required
								style={{
									border: "none !important",
								}}
								name="oldPassword"
								className="form-control py-3 dmMonoFont"
								value={state?.oldPassword}
								onChange={textChange("oldPassword")}
								placeholder="Old Password"
							/>
							<div className="tw-absolute tw-top-4 tw-right-4">
								<EyeToggle typePass={typePass} setTypePass={setTypePass} />
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<label className="dmMonoFont text-uppercase" htmlFor="password">
						new Password
					</label>
					{/* <input
						type="password"
						className="form-control py-3 dmMonoFont"
						name="password"
						placeholder="New Password"
						value={state?.newPassword}
						onChange={textChange("newPassword")}
					/> */}
					<div className="tw-relative">
						<div className="tw-flex tw-h-full tw-w-full tw-items-center">
							<input
								type={typePass ? "text" : "password"}
								maxLength={11}
								required
								style={{
									border: "none !important",
								}}
								name="newPassword"
								className="form-control py-3 dmMonoFont"
								value={state?.newPassword}
								onChange={textChange("newPassword")}
								placeholder="New Password"
							/>
							<div className="tw-absolute tw-top-4 tw-right-4">
								<EyeToggle typePass={typePass} setTypePass={setTypePass} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="d-flex align-items-center my-3">
				<Buttons
					onClick={handleSubmit}
					loading={loading}
					css="btn-primary1 text-capitalize px-md-4 px-3 fontReduce mx-md-3 mx-2 py-2 text-white"
					title={"save"}
					width="w-auto"
				/>
				<button
					onClick={() => setState(init)}
					className={`btn btn-outline-primary1 text-capitalize px-md-4 px-3 fontReduce mx-md-3 mx-2 py-2 text-primary1`}>
					cancel
				</button>
			</div>
		</form>
	);
};

export const WalletPinBox = () => {
	let { manageWalletPin, wallet } = useContext(GlobalState);
	let init = {
			pin: "",
			oldPin: "",
		},
		[state, setState] = useState(init),
		[loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		[isDelete, setIsDelete] = useState(null),
		// textChange =
		// 	name =>
		// 	({ target: { value } }) => {
		// 		setState({ ...state, [name]: value });
		// 	},
		handleSubmit = async e => {
			e?.preventDefault();
			if (wallet?.balance?.wallet_pin && !isDelete)
				if (!state?.pin || !state?.oldPin) return;
				else if (!state?.pin) return;
			setLoading(isDelete || "load");
			await manageWalletPin(
				isDelete ? isDelete : wallet?.balance?.wallet_pin ? "put" : "post",
				state
			);
			setLoading(false);
			setSubmit(true);
		};

	useEffect(() => {
		if (submit && wallet?.isWalletPin) {
			setState(init);
			setSubmit(false);
			setIsDelete(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit, wallet?.isWalletPin]);

	return (
		<form
			onSubmit={handleSubmit}
			className="py-4 py-md-5 border-top border-bottom">
			<h6 className="Lexend fw-600 fontReduceBig text-uppercase mt-3 mt-md-5">
				manage transaction pin
			</h6>
			<small className="">
				{wallet?.balance?.wallet_pin
					? `Please enter your current pin to change your pin.`
					: `Add transaction pin`}
			</small>
			<div className="row mx-0 g-5 col-md-8 mt-3">
				{wallet?.balance?.wallet_pin && (
					<div className="col-md-4">
						<label className="dmMonoFont text-uppercase" htmlFor="password">
							old pin <small className="text-muted">4 digits</small>
						</label>
						{/* <input
							type="number"
							className="form-control py-3 dmMonoFont"
							name="password"
							placeholder="Old pin"
							value={state?.oldPin}
							onChange={textChange("oldPin")}
							maxLength={4}
						/> */}
						<OtpComponent
							stateData={state?.oldPin}
							textChange={data => {
								setState({ ...state, oldPin: data });
							}}
							css="borderColor"
							loading={loading}
							numInputs={4}
							isInputSecure={true}
							inputType={"tel"}
							shouldAutoFocus={"shouldAutoFocus"}
						/>
					</div>
				)}
				<div className="col-md-4">
					<label className="dmMonoFont text-uppercase" htmlFor="password">
						{wallet?.balance?.wallet_pin ? `new ` : ""} Pin
						<small className="text-muted">4 digits</small>
					</label>
					{/* <input
						type="number"
						className="form-control py-3 dmMonoFont"
						name="password"
						placeholder="Pin"
						value={state?.pin}
						onChange={textChange("pin")}
						maxLength={4}
					/> */}
					<OtpComponent
						isInputSecure={true}
						stateData={state?.pin}
						textChange={data => {
							setState({ ...state, pin: data });
						}}
						css="borderColor"
						loading={loading}
						numInputs={4}
						inputType={"tel"}
						shouldAutoFocus={"shouldAutoFocus"}
					/>
				</div>
			</div>
			<div className="d-flex align-items-center my-3">
				<Buttons
					onClick={handleSubmit}
					loading={loading === "load"}
					css="btn-primary1 text-capitalize px-md-4 px-3 fontReduce mx-md-3 mx-2 py-2 text-white"
					title={"save"}
					width="w-auto"
				/>
				<button
					onClick={() => setState(init)}
					type="button"
					className={`btn btn-outline-primary1 text-capitalize px-md-4 px-3 fontReduce mx-md-3 mx-2 py-2 text-primary1`}>
					cancel
				</button>
				{wallet?.balance?.wallet_pin && (
					<div className="col-md-4">
						<Buttons
							onClick={() => setIsDelete("delete")}
							loading={loading === "load"}
							css="btn-danger2 text-capitalize px-md-4 px-3 fontReduce mx-md-3 mx-2 py-2 text-white"
							title={"Disable pin"}
							width="w-auto"
							type={"button"}
						/>
					</div>
				)}
			</div>
			<ModalComponents
				isOpen={isDelete ? true : false}
				toggle={() => setIsDelete(null)}
				title={"Disable Transaction Pin"}>
				<TransactionPinBox
					state={state}
					setState={setState}
					handleSubmit={handleSubmit}
					loading={loading === "delete"}
					title="Disable"
				/>
			</ModalComponents>
		</form>
	);
};

export const DeleteAccountBox = () => {
	let [step, setStep] = useState(""),
		[typePass, setTypePass] = useState(""),
		[password, setPassword] = useState(""),
		{ logoutUser } = useContext(GlobalState),
		navigate = useNavigate(),
		[loading, setLoading] = useState(false),
		handleSubmit = async e => {
			e?.preventDefault();
			if (!password) return toast.info("Password required");
			setLoading(true);
			try {
				let url = `/api/v2/user/delete-account`;
				var res;
				res = await axios.post(url, { password });
				console.log({ res: res?.data }, step);
				toast.success(res.data.msg || res?.data?.message);
				logoutUser();
				navigate("/");
				setLoading(false);
			} catch (err) {
				console.log({ err });
				let error = err.response?.data?.error;
				if (error) {
					error.forEach(error => toast.error(error.msg || error?.message));
				}

				if (err?.response?.status === 429) toast.error(err?.response?.data);
				setLoading(false);
			}
			setLoading(false);
		};

	return (
		<form className="py-4 py-md-5">
			<h6 className="Lexend fw-600 fontReduceBig text-uppercase mt-3 mt-md-5">
				Delete Account
			</h6>
			<small className="">Action is irreversible</small>
			<div className="d-flex align-items-center my-3">
				<Buttons
					onClick={() => setStep("delete")}
					loading={loading === "load"}
					css="btn-danger2 text-capitalize px-md-4 px-3 fontReduce mx-md-3 mx-2 py-2 text-white"
					title={"Delete Account"}
					width="w-auto"
					type={"button"}
				/>
			</div>
			<ModalComponents
				isOpen={step}
				toggle={() => setStep(null)}
				title={`Account Deletion`}>
				<div className="downH2 d-flex flex-column align-items-center">
					<div className="my-auto w-100">
						<p className="text2 Lexend text-center">
							Do you want to <strong className="text2 Lexend">delete</strong>{" "}
							your account?
						</p>
						<p className="text-lg Lexend text-center">
							Action is <strong>Irreversible</strong>
						</p>
						<form onSubmit={handleSubmit} className="w-100">
							<div className="py-3">
								<label className="dmMonoFont text-uppercase" htmlFor="password">
									Password
								</label>
								<div className="tw-relative">
									<div className="tw-flex tw-h-full tw-w-full tw-items-center">
										<input
											type={typePass ? "text" : "password"}
											required
											style={{
												border: "none !important",
											}}
											name="password"
											className="form-control py-3 dmMonoFont"
											value={password}
											onChange={e => setPassword(e?.target?.value)}
											placeholder="Password"
										/>
										<div className="tw-absolute tw-top-4 tw-right-4">
											<EyeToggle
												typePass={typePass}
												setTypePass={setTypePass}
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
						<div className="w-100 d-flex">
							<div className="btn-group mx-auto w-50">
								<Buttons
									onClick={handleSubmit}
									loading={loading}
									title={"yes"}
									css="btn btn-success2 text-uppercase py-3"
									width={"auto"}
									type="submit"
								/>
								<button
									className="btn btn-danger text-uppercase py-3"
									type="button"
									onClick={() => setStep(null)}>
									no
								</button>
							</div>
						</div>
					</div>
				</div>
			</ModalComponents>
		</form>
	);
};
