import {
	SEARCH_AIRTIME_RELOAD,
	SEARCH_BIZ_RELOAD,
	SEARCH_CABLE_RELOAD,
	SEARCH_CONVERTER_RELOAD,
	SEARCH_DATA_RELOAD,
	SEARCH_EDUCATION_RELOAD,
	SEARCH_ELECTRICITY_RELOAD,
	SEARCH_RELOAD,
	SEARCH_TRANSACTION_RELOAD,
	SEARCH_WALLET_RELOAD,
} from "./ActionTypes";

export const getReload = () => async dispatch => {
	dispatch({ type: SEARCH_DATA_RELOAD });
	dispatch({ type: SEARCH_AIRTIME_RELOAD });
	dispatch({ type: SEARCH_EDUCATION_RELOAD });
	dispatch({ type: SEARCH_BIZ_RELOAD });
	dispatch({ type: SEARCH_ELECTRICITY_RELOAD });
	dispatch({ type: SEARCH_CABLE_RELOAD });
	dispatch({ type: SEARCH_WALLET_RELOAD });
	dispatch({ type: SEARCH_TRANSACTION_RELOAD });
	dispatch({ type: SEARCH_CONVERTER_RELOAD });
	dispatch({ type: SEARCH_RELOAD });
};
