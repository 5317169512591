import axios from "axios";
import { clearErrors, returnErrors } from "../Reducer/ErrorReducer";
import {
	SET_SUCCESS,
	UPDATE_NOTIFICATION,
	GET_MY_NOTIFICATONS,
	GET_NOTIFICATONS,
	GET_MY_NOTIFICATONS_FAIL,
	GET_NOTIFICATONS_FAIL,
	DELETE_NOTIFICATONS,
	UPDATE_NOTIFICATONS,
	ADD_NOTIFICATONS,
	ADD_NOTIFICATONS_FAIL,
	DELETE_BANK,
	ADD_BANK,
	GET_BANKS_ADDED,
	ADD_WITHDRAWAL,
	GET_WITHDRAWAL,
	ADD_WITHDRAWAL_FAIL,
	MANAGE_WALLET_PIN,
	TRANSFER_FUND_FAIL,
	GET_FAQS,
	ADD_FAQS_FAIL,
	GET_TERMS_CONDITIONS,
	GET_PRIVACY_POLICY,
	GET_NOTIFICATONS_INFORMED,
} from "./ActionTypes";
import { toast } from "react-toastify";
import { getWalletBalance } from "./GeneralAction";
import { useURL, useURL3, useURL5 } from "../Config";

export const readNotification = data => async dispatch => {
	try {
		let res = await axios.put(
			`/api/v2/notification/${data}`,
			{},
			{
				baseURL: useURL5|| useURL3 || useURL,
			}
		);

		dispatch({
			type: UPDATE_NOTIFICATION,
			payload: res.data?.data,
		});
	} catch (err) {
		if (err) console.log({ err });
		if (err) console.log(err?.response ? err?.response?.data : err?.message);
	}
};

export const getNotify = (type, data) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res = await axios.get(
			`/api/v2/notification?type=${type}${
				data?.limit ? `&limit=${data?.limit}` : ""
			}${!data?.page ? "" : `&page=${data.page}`}${
				data?.status ? `&status=${data?.status}` : ""
			}`,
			{
				baseURL: useURL5 || useURL3 || useURL,
			}
		);
		dispatch({
			type:
				type !== "incoming"
					? GET_MY_NOTIFICATONS
					: data?.status
					? GET_NOTIFICATONS_INFORMED
					: GET_NOTIFICATONS,
			payload: res.data,
		});
	} catch (err) {
		if (err) console.log(err.response?.data?.error, { err });
		// if (err?.response?.status === 429 || err?.response?.status === 405)
		// 	toast.error(err?.response?.data ? err?.response?.data : err?.message);
		dispatch({
			type:
				type !== "incoming" ? GET_MY_NOTIFICATONS_FAIL : GET_NOTIFICATONS_FAIL,
		});
	}
};

export const manageNotify = (data, id, prior) => async dispatch => {
	try {
		let res;
		if (!id)
			res = await axios.post(
				`/api/v2/notification`,
				{ ...data },
				{
					baseURL: useURL3 || useURL,
				}
			);
		else if (prior === "delete")
			res = await axios.delete(`/api/v2/notification/${id}`, {
				baseURL: useURL3 || useURL,
			});
		else if (prior === "deleteme")
			res = await axios.post(
				`/api/v2/notification/${id}`,
				{},
				{
					baseURL: useURL3 || useURL,
				}
			);
		else if (prior)
			res = await axios.put(
				`/api/v2/notification/${id}`,
				{},
				{
					baseURL: useURL3 || useURL,
				}
			);
		else
			res = await axios.post(
				`/api/v2/notification/${id}`,
				{},
				{
					baseURL: useURL3 || useURL,
				}
			);
		dispatch({
			type: id
				? ["delete", "deleteme"]?.includes(prior)
					? DELETE_NOTIFICATONS
					: UPDATE_NOTIFICATONS
				: ADD_NOTIFICATONS,
			payload: ["delete", "deleteme"]?.includes(prior) ? data : res.data,
		});
		if (!id || ["delete", "deleteme"]?.includes(prior))
			dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
	} catch (err) {
		if (err) console.log(err.response?.data?.error, { err });
		if (!id || ["delete", "deleteme"]?.includes(prior))
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
		let error = err.response?.data?.error;
		if (error) dispatch(returnErrors({ error, status: err?.response?.status }));
		// error.forEach(error =>
		// 	error?.param
		// 		? error?.param !== "suggestion" &&
		// 		  toast.error(error.msg, { autoClose: false })
		// 		: toast.error(error.msg, { autoClose: false })
		// );
		dispatch({ type: ADD_NOTIFICATONS_FAIL });
	}
};
export const manageBanks = (method, data) => async dispatch => {
	try {
		let res;
		if (method === "post")
			res = await axios.put(`/api/v2/user/account/manage-bank`, { ...data });
		else if (method === "delete")
			res = await axios.delete(`/api/v2/user/account/manage-bank`);
		else
			res = await axios.get(`/api/v2/user/account/manage-bank`, {
				baseURL: useURL3 || useURL,
			});
		dispatch({
			type:
				method === "delete"
					? DELETE_BANK
					: method === "post"
					? ADD_BANK
					: GET_BANKS_ADDED,
			payload: method === "delete" ? data : res.data,
		});
		if (method !== "get")
			dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
	} catch (err) {
		if (err) console.log(err.response?.data?.error, { err });
		if (err?.response?.status === 429 || err?.response?.status === 405)
			toast.error(err?.response?.data ? err?.response?.data : err?.message);
		let error = err.response?.data?.error;
		if (error) dispatch(returnErrors({ error, status: err?.response?.status }));
		dispatch({ type: ADD_WITHDRAWAL_FAIL });
	}
};

export const manageWithdrawal = (method, data) => async dispatch => {
	try {
		let res;
		if (method === "post")
			res = await axios.post(
				`/api/v2/wallet/manage-withdrawal`,
				{ ...data },
				{
					baseURL: useURL3 || useURL,
				}
			);
		else
			res = await axios.get(`/api/v2/wallet/manage-withdrawal`, {
				baseURL: useURL3 || useURL,
			});
		dispatch({
			type: method === "post" ? ADD_WITHDRAWAL : GET_WITHDRAWAL,
			payload: res.data,
		});
		if (method !== "get") {
			dispatch(getWalletBalance());
			dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
		}
	} catch (err) {
		if (err) console.log(err.response?.data?.error, { err });
		if (err?.response?.status === 429 || err?.response?.status === 405)
			toast.error(err?.response?.data ? err?.response?.data : err?.message);
		let error = err.response?.data?.error;
		if (error) dispatch(returnErrors({ error, status: err?.response?.status }));
		dispatch({ type: ADD_WITHDRAWAL_FAIL });
	}
};

export const manageWalletPin = (method, data) => async dispatch => {
	try {
		console.log({ method, data });
		let res;
		if (method === "post")
			res = await axios.post(
				`/api/v2/wallet/manage-wallet-pin`,
				{ ...data },
				{
					baseURL: useURL3 || useURL,
				}
			);
		else if (method === "delete")
			res = await axios.delete(`/api/v2/wallet/manage-wallet-pin`, {
				data,
				baseURL: useURL3 || useURL,
			});
		else
			res = await axios.put(
				`/api/v2/wallet/manage-wallet-pin`,
				{ ...data },
				{
					baseURL: useURL3 || useURL,
				}
			);
		dispatch({
			type: MANAGE_WALLET_PIN,
			payload: res.data,
		});
		dispatch(getWalletBalance());
		dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
	} catch (err) {
		if (err) console.log(err.response?.data?.error, { err });
		if (err?.response?.status === 429 || err?.response?.status === 405)
			toast.error(err?.response?.data ? err?.response?.data : err?.message);
		let error = err.response?.data?.error;
		if (error) dispatch(returnErrors({ error, status: err?.response?.status }));
		dispatch({ type: TRANSFER_FUND_FAIL });
	}
};

export const manageFaqs = scene => async dispatch => {
	try {
		let res = await axios.get(`/api/v2/faqs${scene ? `?type=${scene}` : ""}`, {
			baseURL: useURL3 || useURL,
		});

		dispatch({
			type:
				scene === "terms"
					? GET_TERMS_CONDITIONS
					: scene === "privacy"
					? GET_PRIVACY_POLICY
					: GET_FAQS,
			payload: res.data?.data,
		});
	} catch (err) {
		if (err?.response?.status === 429 || err?.response?.status === 405)
			toast.error(err?.response?.data ? err?.response?.data : err?.message);
		console.log({ err });
		let error = err.response?.data?.error;
		if (error) {
			dispatch(returnErrors({ error, status: err?.response?.status }));
		}
		dispatch({ type: ADD_FAQS_FAIL });
	}
};