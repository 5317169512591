import moment from "moment";
import React, { useContext, useEffect, useState, useRef } from "react";
import icon1 from "../../Assets/Fresh Folk Teamwork.png";
import icon2 from "../../Assets/Finance.png";
import icon3 from "../../Assets/Support.png";
import { ThreeBoxBar } from "../Users";
import { GlobalState } from "../../Data/Context";
import { Buttons } from "../../Utils";
import LoadMore, { BottomTab } from "../LoadMore";
import { useParams } from "react-router-dom";
import { ModalComponents } from "../DefaultHeader";
import { useReactToPrint } from "react-to-print";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { BiCopy } from "react-icons/bi";
import { downloadExcel } from "react-export-table-to-excel";
import { PatternFormat } from "react-number-format";
import qr from "qrcode";

const TransactionsFolder = ({ subActive = 0 }) => {
	return <SubTransactionsFolder subActive={subActive} />;
};

export const SubTransactionsFolder = ({ subActive = 0 }) => {
	const { transactions, getServicesHistory, getReload } =
		useContext(GlobalState);

	let [loading, setLoading] = useState(false),
		{ page } = useParams(),
		[search, setSearch] = useState(""),
		[streamline, setStreamline] = useState(""),
		[state, setState] = useState(null),
		[thisData, setThisData] = useState(null);

	useEffect(() => {
		if (search) {
			document.getElementById("Search").addEventListener("search", () => {
				getReload();
			});
			let handleSubmit = async () => {
				if (!search) return;

				await getServicesHistory("all", {
					search,
					streamline,
					filter: `${
						stateFilter?.category ? `&category=${stateFilter?.category}` : ""
					}${stateFilter?.network ? `&network=${stateFilter?.network}` : ""}${
						stateFilter?.status ? `&status=${stateFilter?.status}` : ""
					}${stateFilter?.type ? `&caseType=${stateFilter?.type}` : ""}${
						stateFilter?.startDate ? `&startDate=${stateFilter?.startDate}` : ""
					}${stateFilter?.endDate ? `&endDate=${stateFilter?.endDate}` : ""}${
						stateFilter?.channel ? `&channel=${stateFilter?.channel}` : ""
					}`,
				});
			};
			handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	useEffect(() => {
		if (transactions.isFound) {
			setState(transactions.mainSearch);
		} else
			setState(
				subActive === 1
					? transactions?.month_transactions
					: subActive === 0
					? transactions?.day_transactions
					: transactions.transactions
			);
		if (subActive === 1) {
			setStreamline("month");
		}
		if (subActive === 0) {
			setStreamline("day");
		}
		if (subActive === 2) {
			setStreamline("");
		}
	}, [
		transactions.transactions,
		transactions.day_transactions,
		transactions.month_transactions,
		transactions.isFound,
		transactions.mainSearch,
		subActive,
	]);

	useEffect(() => {
		getReload();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getServicesHistory("all", streamline ? { streamline } : null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [streamline]);

	let handleLoadMore = async () => {
			setLoading(true);

			if (search) {
				await getServicesHistory("all", {
					page: Number(transactions?.paginate?.nextPage),
					// limit: Number(
					// 	transactions?.paginate?.nextPage * transactions?.paginate?.limit
					// ),
					search,
					streamline,
					filter: `${
						stateFilter?.category ? `&category=${stateFilter?.category}` : ""
					}${stateFilter?.network ? `&network=${stateFilter?.network}` : ""}${
						stateFilter?.status ? `&status=${stateFilter?.status}` : ""
					}${stateFilter?.type ? `&caseType=${stateFilter?.type}` : ""}${
						stateFilter?.startDate ? `&startDate=${stateFilter?.startDate}` : ""
					}${stateFilter?.endDate ? `&endDate=${stateFilter?.endDate}` : ""}${
						stateFilter?.channel ? `&channel=${stateFilter?.channel}` : ""
					}`,
				});
			} else {
				await getServicesHistory("all", {
					page:
						subActive === 1
							? Number(transactions?.month_paginate?.nextPage)
							: subActive === 0
							? Number(transactions?.day_paginate?.nextPage)
							: Number(transactions?.paginate?.nextPage),
					// limit:
					// 	subActive === 1
					// 		? Number(
					// 				transactions?.month_paginate?.nextPage *
					// 					transactions?.month_paginate?.limit
					// 		  )
					// 		: subActive === 0
					// 		? Number(
					// 				transactions?.day_paginate?.nextPage *
					// 					transactions?.day_paginate?.limit
					// 		  )
					// 		: Number(
					// 				transactions?.paginate?.nextPage *
					// 					transactions?.paginate?.limit
					// 		  ),
					streamline,
					filter: `${
						stateFilter?.category ? `&category=${stateFilter?.category}` : ""
					}${stateFilter?.network ? `&network=${stateFilter?.network}` : ""}${
						stateFilter?.status ? `&status=${stateFilter?.status}` : ""
					}${stateFilter?.type ? `&caseType=${stateFilter?.type}` : ""}${
						stateFilter?.startDate ? `&startDate=${stateFilter?.startDate}` : ""
					}${stateFilter?.endDate ? `&endDate=${stateFilter?.endDate}` : ""}${
						stateFilter?.channel ? `&channel=${stateFilter?.channel}` : ""
					}`,
				});
			}
			setLoading(false);
		},
		init = { category: "", network: "", type: "", status: "" },
		[stateFilter, setStateFilter] = useState(init),
		[isOpen, setIsOpen] = useState(false),
		toggle = () => {
			if (isOpen) setStateFilter(init);
			setIsOpen(!isOpen);
		},
		[loadingFilter, setLoadingFilter] = useState(false),
		[submit, setSubmit] = useState(false),
		textChange =
			name =>
			({ target: { value } }) => {
				setStateFilter({ ...stateFilter, [name]: value });
			},
		handleFind = async () => {
			setLoadingFilter(true);
			await getServicesHistory("all", {
				search,
				streamline,
				filter: `${
					stateFilter?.category ? `&category=${stateFilter?.category}` : ""
				}${stateFilter?.network ? `&network=${stateFilter?.network}` : ""}${
					stateFilter?.status ? `&status=${stateFilter?.status}` : ""
				}${stateFilter?.type ? `&caseType=${stateFilter?.type}` : ""}${
					stateFilter?.startDate ? `&startDate=${stateFilter?.startDate}` : ""
				}${stateFilter?.endDate ? `&endDate=${stateFilter?.endDate}` : ""}${
					stateFilter?.channel ? `&channel=${stateFilter?.channel}` : ""
				}`,
			});
			setLoadingFilter(false);
			setSubmit(true);
		};

	useEffect(() => {
		if (submit && transactions?.isFound) {
			setSubmit(false);
			toggle();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit, transactions?.isFound]);

	if (!state) return <></>;

	return (
		<>
			{page === "transactions" && (
				<Buttons
					title={"filter"}
					css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
					width={"w-25 w25 mb-3"}
					onClick={toggle}
					style={{ borderRadius: "30px" }}
				/>
			)}
			{page !== "dashboard" && (
				<>
					<div className="w-50 w50">
						<input
							type="search"
							name="search"
							id="Search"
							className="form-control w-100 py-3 borderColor2"
							placeholder="Type here to search"
							value={search}
							onChange={e => setSearch(e.target.value)}
						/>
					</div>
				</>
			)}
			<NewPaginate
				state={page !== "dashboard" ? state : state?.slice(0, 10)}
				setThisData={setThisData}
				setState={setState}
				type={"all"}
				criteria={{
					page:
						subActive === 1
							? transactions?.month_paginate?.nextPage
							: subActive === 0
							? transactions?.day_paginate?.nextPage
							: transactions?.paginate?.nextPage,
					// limit:
					// 	subActive === 1
					// 		? transactions?.month_paginate?.limit
					// 		: subActive === 0
					// 		? transactions?.day_paginate?.limit
					// 		: transactions?.paginate?.limit,
					search: search ? search : "",
					streamline,
				}}
			/>

			<>
				{page !== "dashboard" && (
					<>
						<BottomTab
							state={state}
							paginate={
								search
									? transactions?.search_paginate
									: subActive === 1
									? transactions?.month_paginate
									: subActive === 0
									? transactions?.day_paginate
									: transactions?.paginate
							}
						/>
						<LoadMore
							next={
								search
									? transactions?.search_paginate?.next
									: subActive === 1
									? transactions?.month_paginate?.next
									: subActive === 0
									? transactions?.day_paginate?.next
									: transactions?.paginate?.next
							}
							handleLoadMore={handleLoadMore}
							loading={loading}
						/>
					</>
				)}
			</>
			<TransactionDetails
				type={"all"}
				criteria={{
					page:
						subActive === 1
							? transactions?.month_paginate?.nextPage
							: subActive === 0
							? transactions?.day_paginate?.nextPage
							: transactions?.paginate?.nextPage,
					// limit:
					// 	subActive === 1
					// 		? transactions?.month_paginate?.limit
					// 		: subActive === 0
					// 		? transactions?.day_paginate?.limit
					// 		: transactions?.paginate?.limit,
					search: search ? search : "",
					streamline,
				}}
				thisData={thisData}
				setThisData={setThisData}
			/>
			<FindDetails
				isOpen={isOpen}
				back={toggle}
				state={stateFilter}
				textChange={textChange}
				handleFind={handleFind}
				loading={loadingFilter}
				filter
			/>
		</>
	);
};

export const TransactionDetails = ({
	thisData,
	setThisData,
	type,
	criteria,
}) => {
	let { auth, manageTransaction, transactions } = useContext(GlobalState),
		[loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false);

	useEffect(() => {
		if (submit && transactions?.isDeleted) setThisData(false);
		if (submit && transactions?.isUpdated) setThisData(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transactions?.isDeleted, submit, transactions?.isUpdated]);

	return (
		<>
			<ModalComponents
				isOpen={thisData ? true : false}
				toggle={() => setThisData(false)}
				title="Transaction details">
				<div className="downH2 d-flex flex-column">
					<PDFMode thisData={thisData} setThisData={setThisData} />
					{auth?.user?.isAdmin && (
						<div className="py-2 btn-group">
							{/* {thisData?.code === 300 || !thisData?.properties?.resData ? (
								<Buttons
									title={"delete transaction"}
									css="btn btn-danger-2 btn-danger2 py-2 py-md-3 text-capitalize Lexend d-block"
									width={"mx-auto"}
									loading={loading === "mark-delete"}
									onClick={async () => {
										setLoading("mark-delete");
										await manageTransaction(
											thisData,
											type,
											criteria,
											null,
											"mark-delete"
										);
										setLoading("");
										setSubmit(true);
									}}
								/>
							) : null} */}
							{thisData?.code === 400 ? (
								<>
									<Buttons
										title={"fail transaction"}
										css="btn mx-md-2 btn-primary1 py-2 py-md-3 text-capitalize Lexend d-block"
										width={"mx-auto"}
										loading={loading === "mark-fail"}
										onClick={async () => {
											setLoading("mark-fail");
											await manageTransaction(
												thisData,
												type,
												criteria,
												null,
												"mark-fail"
											);
											setLoading("");
											setSubmit(true);
										}}
									/>
									<Buttons
										title={"validate transaction"}
										css="btn btn-success-2 btn-success2 py-2 py-md-3 text-capitalize Lexend d-block"
										width={"mx-auto"}
										loading={loading === "mark-success"}
										onClick={async () => {
											setLoading("mark-success");
											await manageTransaction(
												thisData,
												type,
												criteria,
												null,
												"mark-success"
											);
											setLoading("");
											setSubmit(true);
										}}
									/>
								</>
							) : null}
						</div>
					)}
				</div>
			</ModalComponents>
		</>
	);
};

const PDFMode = ({ thisData, setThisData }) => {
	let { numberWithCommas, auth, nairaSign } = useContext(GlobalState);
	let ref = useRef();
	const handlePrint = useReactToPrint({
		content: () => ref.current,
		documentTitle: `${
			process.env.REACT_APP_NAME
		}-${thisData?.type?.toUpperCase()}-${moment(thisData.createdAt).format(
			"dddd, L"
		)}`,
		bodyClass: "p-2",
	});

	return (
		<>
			<div ref={ref}>
				<div className="printOnly d-none">
					<div className="d-flex justify-content-center flex-column bg-white">
						<img
							src={process.env.REACT_APP_IMAGE_URL}
							alt="Logo"
							className="mx-auto rounded"
							style={{ height: "70px", width: "auto" }}
						/>
						<div className="text-center">
							<h3 className="Lexend fw-bold">{process.env.REACT_APP_NAME}</h3>
							<p className="my-0 Lexend">{thisData?.type?.toUpperCase()}</p>
							<p className="mt-0">
								Reciept:{" "}
								<span className="fontInherit Lexend">{thisData?.item_id}</span>{" "}
							</p>
						</div>
					</div>
					<hr />
				</div>
				<p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
					<span>Id: </span>
					<span
						className="fontInherit Lexend force-d-flex myCursor"
						onClick={
							thisData?.item_id
								? () => {
										navigator.clipboard.writeText(thisData?.item_id).then(
											() => {
												toast.info("Copied", { autoClose: 2000 });
											},
											err => {
												toast.warn(`Could not copy: ${err}`, {
													autoClose: 2000,
												});
											}
										);
								  }
								: null
						}>
						{thisData?.item_id}
						<BiCopy />{" "}
					</span>{" "}
				</p>
				{auth?.user?.isAdmin && (
					<p className="text-capitalize border-bottom d-flex justify-content-between">
						<span>reference: </span>
						<span
							className="fontInherit Lexend force-d-flex myCursor"
							onClick={
								thisData?.reference
									? () => {
											navigator.clipboard.writeText(thisData?.reference).then(
												() => {
													toast.info("Copied", { autoClose: 2000 });
												},
												err => {
													toast.warn(`Could not copy: ${err}`, {
														autoClose: 2000,
													});
												}
											);
									  }
									: null
							}>
							{thisData?.reference}
							<BiCopy />
						</span>{" "}
					</p>
				)}
				<p className="text-capitalize border-bottom d-flex justify-content-between">
					<span>type: </span>
					<span className="fontInherit Lexend">{thisData?.type}</span>{" "}
				</p>
				<p className="text-capitalize border-bottom d-flex justify-content-between">
					<span>category: </span>
					<span className="fontInherit Lexend">
						{thisData?.transactionType?.replace(/[_]/g, " ")}
					</span>{" "}
				</p>
				{auth?.user?.isAdmin && (
					<p className="text-capitalize border-bottom d-flex justify-content-between">
						<span>provider: </span>
						<span className="fontInherit Lexend text-uppercase">
							{thisData?.provider}
						</span>{" "}
					</p>
				)}
				<p className="text-capitalize border-bottom d-flex justify-content-between">
					<span>channel: </span>
					<span className="fontInherit Lexend text-uppercase">
						{thisData?.channel}
					</span>{" "}
				</p>
				{auth?.user?.isAdmin && (
					<>
						<p className="border-bottom d-flex justify-content-between">
							<span className="text-capitalize">User: </span>
							<span>
								<span className="fontInherit Lexend d-block text-capitalize">
									{thisData?.user?.lastName} {thisData?.user?.firstName}
								</span>{" "}
								<span className="fontInherit Lexend d-block">
									{thisData?.user?.telephone}
								</span>{" "}
								<span className="fontInherit Lexend d-block">
									{thisData?.user?.email}
								</span>{" "}
							</span>
						</p>
						{thisData?.deviceProperties && (
							<p className="border-bottom d-flex justify-content-between">
								<span className="text-capitalize">Device property: </span>
								<span>
									<span className="fontInherit Lexend d-block text-capitalize">
										OS: {thisData?.deviceProperties?.os?.name} -{" "}
										{thisData?.deviceProperties?.os?.version}
									</span>{" "}
									<span className="fontInherit Lexend d-block text-capitalize">
										client: {thisData?.deviceProperties?.client?.type} -{" "}
										{thisData?.deviceProperties?.client?.name}
									</span>{" "}
									<span className="fontInherit Lexend d-block text-capitalize">
										Device: {thisData?.deviceProperties?.device?.type} -{" "}
										{thisData?.deviceProperties?.device?.brand} -{" "}
										{thisData?.deviceProperties?.device?.model}
									</span>{" "}
									<span className="fontInherit Lexend d-block">
										IP: {thisData?.deviceProperties?.ip}
									</span>{" "}
								</span>
							</p>
						)}
					</>
				)}
				<p className="text-capitalize border-bottom d-flex justify-content-between">
					<span>
						{thisData?.type === "cables"
							? "Smart Card Number"
							: thisData?.type === "electricity"
							? "Meter Number"
							: "recipient"}
						:{" "}
					</span>
					<span
						className="fontInherit Lexend force-d-flex myCursor"
						onClick={
							thisData?.properties
								? () => {
										navigator.clipboard
											.writeText(
												thisData?.type === "cables" ? (
													thisData?.properties?.smartCardNo
												) : thisData?.type === "electricity" ? (
													thisData?.properties?.meterNo
												) : thisData?.type === "airtime" ? (
													thisData?.properties?.phone
												) : thisData?.type === "education" ? (
													<>
														{thisData?.user?.lastName}{" "}
														{thisData?.user?.firstName}
													</>
												) : thisData?.type === "data" ? (
													thisData?.properties?.phone
												) : thisData?.type === "biz" ? (
													thisData?.properties?.name
												) : thisData?.type === "verify" ? (
													thisData?.properties?.verify
												) : (
													""
												)
											)
											.then(
												() => {
													toast.info("Copied", { autoClose: 2000 });
												},
												err => {
													toast.warn(`Could not copy: ${err}`, {
														autoClose: 2000,
													});
												}
											);
								  }
								: null
						}>
						{thisData?.type === "cables" ? (
							thisData?.properties?.smartCardNo
						) : thisData?.type === "electricity" ? (
							thisData?.properties?.meterNo
						) : thisData?.type === "airtime" ? (
							thisData?.properties?.phone
						) : thisData?.type === "education" ? (
							<>
								{thisData?.user?.lastName} {thisData?.user?.firstName}
							</>
						) : thisData?.type === "data" ? (
							thisData?.properties?.phone
						) : thisData?.type === "biz" ? (
							thisData?.properties?.name
						) : thisData?.type === "verify" ? (
							thisData?.properties?.verify
						) : (
							""
						)}
						<BiCopy />
					</span>{" "}
				</p>
				{thisData?.type === "electricity" &&
					thisData?.properties?.phoneNumber && (
						<p className="text-capitalize border-bottom d-flex justify-content-between">
							<span>Phone Number : </span>
							<span
								className="fontInherit Lexend force-d-flex myCursor"
								onClick={
									thisData?.properties?.phoneNumber
										? () => {
												navigator.clipboard
													.writeText(thisData?.properties?.phoneNumber)
													.then(
														() => {
															toast.info("Copied", { autoClose: 2000 });
														},
														err => {
															toast.warn(`Could not copy: ${err}`, {
																autoClose: 2000,
															});
														}
													);
										  }
										: null
								}>
								{thisData?.properties?.phoneNumber}
								<BiCopy />
							</span>{" "}
						</p>
					)}
				{/* {thisData?.type !== "data" && (
					<p className="text-capitalize border-bottom d-flex justify-content-between">
						<span>usage: </span>
						<span className="fontInherit Lexend">
							{thisData?.type === "cables"
								? thisData?.properties?.packagename
									? thisData?.properties?.packagename
									: thisData?.properties?.type
								: thisData?.type === "airtime"
								? thisData?.properties?.network
								: thisData?.type === "electricity"
								? thisData?.properties?.disco
								: thisData?.type === "biz"
								? thisData?.properties?.result?.[0]?.rcNumber
								: thisData?.type === "education"
								? thisData?.properties?.type
								: thisData?.type === "data"
								? thisData?.description
								: ""}
						</span>{" "}
					</p>
				)} */}
				{thisData?.type === "data" && (
					<p className="text-capitalize border-bottom d-flex justify-content-between">
						<span>wallet type: </span>
						<span className="fontInherit Lexend">
							{thisData?.walletType?.replace(/[_]/g, " ")}
						</span>{" "}
					</p>
				)}
				<p className="text-capitalize border-bottom d-flex justify-content-between">
					<span>
						{thisData?.walletType === "CG_WALLET" ? "Volume" : "Amount"}:{" "}
					</span>
					<span className="fontInherit Lexend">
						{thisData?.walletType === "CG_WALLET" ? "" : nairaSign}{" "}
						{thisData?.walletType === "CG_WALLET" && thisData?.volume
							? numberWithCommas(Number(thisData?.volume).toFixed(2))
							: thisData?.properties?.amount
							? numberWithCommas(
									Number(thisData?.properties?.amount).toFixed(2)
							  )
							: 0}{" "}
						{thisData?.walletType === "CG_WALLET" ? "GB" : ""}
					</span>{" "}
				</p>
				{thisData?.walletType === "MAIN_WALLET" &&
					thisData?.commission?.amount && (
						<p className="text-capitalize border-bottom d-flex justify-content-between">
							<span>Commission: </span>
							<span className="fontInherit Lexend">
								{nairaSign}{" "}
								{thisData?.commission?.amount
									? numberWithCommas(
											Number(thisData?.commission?.amount).toFixed(2)
									  )
									: 0}{" "}
							</span>{" "}
						</p>
					)}
				{thisData?.prevBalance !== 0 || thisData?.balance !== 0 ? (
					<>
						<p className="text-capitalize border-bottom d-flex justify-content-between">
							<span>
								Previous {thisData?.walletType === "CG_WALLET" ? "CG" : ""}{" "}
								Wallet balance:{" "}
							</span>
							<span className="fontInherit Lexend">
								{thisData?.walletType === "CG_WALLET" ? "" : nairaSign}{" "}
								{thisData?.prevBalance
									? numberWithCommas(Number(thisData?.prevBalance).toFixed(2))
									: 0}{" "}
								{thisData?.walletType === "CG_WALLET" ? "GB" : ""}
							</span>{" "}
						</p>
						<p className="text-capitalize border-bottom d-flex justify-content-between">
							<span>
								Current {thisData?.walletType === "CG_WALLET" ? "CG" : ""}{" "}
								Wallet balance:{" "}
							</span>
							<span className="fontInherit Lexend">
								{thisData?.walletType === "CG_WALLET" ? "" : nairaSign}{" "}
								{thisData?.balance
									? numberWithCommas(Number(thisData?.balance).toFixed(2))
									: 0}{" "}
								{thisData?.walletType === "CG_WALLET" ? "GB" : ""}
							</span>{" "}
						</p>
					</>
				) : (
					<></>
				)}
				<p className="text-capitalize border-bottom d-flex justify-content-between">
					<span className="me-3">Description: </span>
					<span className="fontInherit Lexend">
						{thisData?.description}
					</span>{" "}
				</p>
				{thisData?.type === "electricity" &&
					thisData?.status &&
					thisData?.code === 200 && (
						<>
							<p className="text-capitalize border-bottom d-flex justify-content-between">
								<span>Token: </span>
								{/* <span className="fontInherit Lexend">
								{thisData?.properties?.resData?.token}
							</span> */}
								<span
									className="fontInherit Lexend force-d-flex myCursor"
									onClick={
										thisData?.properties?.token
											? () => {
													navigator.clipboard
														.writeText(thisData?.properties?.token)
														.then(
															() => {
																toast.info("Copied", { autoClose: 2000 });
															},
															err => {
																toast.warn(`Could not copy: ${err}`, {
																	autoClose: 2000,
																});
															}
														);
											  }
											: null
									}>
									{thisData?.properties?.token}
									<BiCopy />
								</span>{" "}
							</p>
							<p className="text-capitalize border-bottom d-flex justify-content-between">
								<span>Unit(s): </span>
								<span className="fontInherit Lexend">
									{thisData?.properties?.unit}
								</span>{" "}
							</p>
						</>
					)}
				{thisData?.type === "education" &&
					thisData?.status &&
					thisData?.code === 200 && (
						<>
							<p className="text-capitalize border-bottom d-flex justify-content-between">
								<span>Pin(s): </span>
								{/* <span className="fontInherit Lexend">
								{thisData?.properties?.resData?.pin?.map((it, i) => (
									<span className="d-block Lexend" key={i}>
										<span
											className="fontInherit Lexend force-d-flex myCursor"
											onClick={
												it
													? () => {
															navigator.clipboard.writeText(it).then(
																() => {
																	toast.info("Copied", { autoClose: 2000 });
																},
																err => {
																	toast.warn(`Could not copy: ${err}`, {
																		autoClose: 2000,
																	});
																}
															);
													  }
													: null
											}>
											{it}
											<BiCopy />
										</span>
									</span>
								))}
							</span>{" "} */}
								<span className="fontInherit Lexend">
									{Array?.isArray(thisData?.properties?.data_pin) ? (
										thisData?.properties?.data_pin?.map((it, i) => (
											<span className="d-block Lexend" key={i}>
												{it?.Serial || it?.pin || it?.serial ? (
													<>
														<span
															className="fontInherit Lexend force-d-flex myCursor"
															onClick={
																it
																	? () => {
																			navigator.clipboard
																				.writeText(
																					`${
																						it?.Serial ||
																						it?.serial ||
																						it?.serialNumber ||
																						""
																					} : ${it?.Pin || it?.pin || ""}`
																				)
																				.then(
																					() => {
																						toast.info("Copied", {
																							autoClose: 2000,
																						});
																					},
																					err => {
																						toast.warn(
																							`Could not copy: ${err}`,
																							{
																								autoClose: 2000,
																							}
																						);
																					}
																				);
																	  }
																	: null
															}>
															{it?.Serial || it?.serial || it?.serialNumber}
															{" : "}
															{it?.Pin || it?.pin}
															<BiCopy />
														</span>
													</>
												) : (
													<>
														<span
															className="fontInherit Lexend force-d-flex myCursor"
															onClick={
																it
																	? () => {
																			navigator.clipboard.writeText(it).then(
																				() => {
																					toast.info("Copied", {
																						autoClose: 2000,
																					});
																				},
																				err => {
																					toast.warn(`Could not copy: ${err}`, {
																						autoClose: 2000,
																					});
																				}
																			);
																	  }
																	: null
															}>
															{it}
															<BiCopy />
														</span>
													</>
												)}
											</span>
										))
									) : (
										<>
											<span
												className="fontInherit Lexend force-d-flex myCursor"
												onClick={
													thisData?.properties?.data_pin?.toString()
														? () => {
																navigator.clipboard
																	.writeText(
																		thisData?.properties?.data_pin?.toString()
																	)
																	.then(
																		() => {
																			toast.info("Copied", { autoClose: 2000 });
																		},
																		err => {
																			toast.warn(`Could not copy: ${err}`, {
																				autoClose: 2000,
																			});
																		}
																	);
														  }
														: null
												}>
												{thisData?.properties?.data_pin?.toString()}
												<BiCopy />
											</span>
										</>
									)}
								</span>
							</p>
							<p className="text-capitalize border-bottom d-flex justify-content-between">
								<span>number of pin(s): </span>
								<span className="fontInherit Lexend">
									{thisData?.properties?.numberOfPin}
								</span>{" "}
							</p>
						</>
					)}
				<p className="text-capitalize border-bottom d-flex justify-content-between">
					<span>date&time: </span>
					<span className="fontInherit Lexend">
						{moment(thisData?.createdAt).format("DD/MM/YYYY hh:mm A")}
					</span>{" "}
				</p>
				<p className="text-capitalize border-bottom d-flex justify-content-between">
					<span>Status: </span>
					<span
						className={`${
							thisData?.code === 200
								? "text-succcess2"
								: thisData?.code === 400
								? "text-dark"
								: "text-danger2"
						} fw-bold fontInherit Lexend`}>
						{thisData?.code === 200
							? "successful"
							: thisData?.code === 400
							? "pending"
							: "failed"}
					</span>{" "}
				</p>
				<div className="printOnly d-none">
					<div className="d-flex justify-content-center flex-column bg-white text-uppercase text-center">
						<h4 className="Lexend">Thanks for your patronage</h4>
					</div>
				</div>
			</div>
			{thisData?.type === "verify" && thisData?.status ? (
				<>
					{/* <PDFVerifyMode
						// thisData={{
						// 	...thisData?.properties,
						// 	result: defaultResult,
						// }}
						thisData={thisData?.properties}
						date={thisData?.createdAt}
					/> */}
					<NewPdfVerifyMode
						// thisData={{
						// 	...thisData?.properties,
						// 	result: defaultResult,
						// }}
						thisData={thisData?.properties}
						date={thisData?.createdAt}
					/>
				</>
			) : null}
			<div className="ms-auto d-flex align-items-center">
				<button
					className="btn btn-danger-2 btn-danger2 d-block ms-auto me-2"
					onClick={() => setThisData(false)}>
					Close
				</button>
				<button
					className="btn btn-primary1 d-block ms-auto"
					onClick={handlePrint}>
					Print
				</button>
			</div>
		</>
	);
};

export const NewPdfVerifyMode = ({ thisData, date, showOthers }) => {
	let ref = useRef(),
		[qrcode, setQrcode] = useState();
	const handlePrint = useReactToPrint({
		content: () => ref.current,
		documentTitle: `${
			process.env.REACT_APP_NAME
		}-${thisData?.verify?.toUpperCase()}-${moment(date).format("dddd, L")}`,
		bodyClass: "p-2",
	});

	useEffect(() => {
		if (thisData) {
			const createQr = async () => {
				try {
					console.log({ thisData });
					let newD = { ...thisData?.result };

					let newURI = {
						surname: newD?.surname,
						firstname: newD?.firstname,
						telephone: newD?.telephoneno,
						nin: newD?.nin,
						// birthdate: newD?.birthdate,
						// centralID:newD?.centralID,
						// gender: newD?.gender,
						// residence_AdressLine1: newD?.residence_AdressLine1,
						// residence_Town: newD?.residence_Town,
						// residence_lga: newD?.residence_lga,
						// residence_state: newD?.residence_state,
						// trackingId: newD?.trackingId,
					};
					const uri = await qr.toDataURL(JSON.stringify(newURI), {
						errorCorrectionLevel: "H",
						type: "image/jpeg",

						// quality: 1,
						margin: 1,
						width: 200,
						// color: {
						//   dark: "#000000",
						//   light: "#0000",
						// },
						scale: 10,
					});

					setQrcode(uri);
				} catch (error) {
					console.error({ msg: error.message, error });
					return { error: error?.message };
				}
			};
			createQr();
		}
	}, [thisData]);

	// console.log({qrCode});

	return (
		<>
			<div ref={ref}>
				<div className={`printOnly ${showOthers ? "" : "d-none"}`}>
					<div className="tw-bg-white tw-w-full tw-flex tw-items-center tw-justify-center">
						<div className="tw-w-1/2 print:tw-w-3/4 tw-mx-auto Kum">
							<div className="tw-bg-green-100 tw-border-b tw-border-b-black tw-px-6 tw-py-6 tw-relative">
								<div>
									{" "}
									<h2 className="tw-text-2xl tw-font-[900] tw-text-[#088935]">
										FEDERAL REPUBLIC OF NIGERIA
									</h2>
									<div>
										<strong className="tw-text-2xl tw-font-[900] tw-text-black">
											DIGITAL NIN SLIP
										</strong>
									</div>
								</div>
								<div className="tw-absolute tw-right-6 tw-top-6">
									{" "}
									<img
										className="tw-h-40 tw-w-40"
										src={qrcode || ""}
										alt={thisData?.result?.surname}
									/>
									<div className="tw-flex tw-justify-center tw-mt-2">
										<div>
											{" "}
											<h3 className="tw-text-3xl tw-font-[900] Kum tw-text-black tw-text-center">
												NGA
											</h3>
											<h6 className="tw-text-sm tw-font-extrabold Kum tw-text-black tw-text-center tw-uppercase">
												issue date
											</h6>
											<strong className="tw-text-[13px] Kum tw-font-semibold tw-text-center tw-text-[#555353] tw-uppercase">
												N/A
											</strong>
										</div>
									</div>
								</div>

								<div className="tw-mt-6">
									<div className="tw-flex tw-gap-6">
										<img
											className="tw-h-40 tw-w-40"
											src={`data:image/png;base64, ${thisData?.result?.photo}`}
											alt={thisData?.result?.surname}
										/>
										<div className="tw-space-y-2">
											<div>
												<div>
													{" "}
													<small className="tw-text-[13px] Kum tw-font-bold tw-text-[#707070] tw-uppercase">
														SURNAME/NOM
													</small>
												</div>
												<strong className="tw-text-[13px] Kum tw-font-bold tw-text-[#555353] tw-uppercase">
													{thisData?.result?.surname}
												</strong>
											</div>
											<div>
												<div>
													{" "}
													<small className="tw-text-[13px] Kum tw-font-bold tw-text-[#707070] tw-uppercase">
														Given names/prenoms
													</small>
												</div>
												<strong className="tw-text-[13px] Kum tw-font-bold tw-text-[#555353] tw-uppercase">
													{thisData?.result?.middlename
														? `${thisData?.result?.middlename}, `
														: ""}{" "}
													{thisData?.result?.firstname}
												</strong>
											</div>
											<div className="tw-flex tw-gap-6">
												<div>
													<div>
														{" "}
														<small className="tw-text-[13px] Kum tw-font-bold tw-text-[#707070] tw-uppercase">
															date of birth
														</small>
													</div>
													<strong className="tw-text-[13px] Kum tw-font-bold tw-text-[#555353] tw-uppercase">
														{thisData?.result?.birthdate
															? moment(thisData?.result?.birthdate).format(
																	"DD MMM YYYY"
															  )
															: null}
													</strong>
												</div>
												<div>
													<div>
														{" "}
														<small className="tw-text-[13px] Kum tw-font-bold tw-text-[#707070] tw-uppercase">
															sex/sexe
														</small>
													</div>
													<strong className="tw-text-[13px] Kum tw-font-bold tw-text-[#555353] tw-uppercase">
														{thisData?.result?.gender}
													</strong>
												</div>
											</div>
										</div>
									</div>
									<div className="tw-mt-4">
										<h4 className="tw-text-lg tw-font-extrabold tw-text-center Kum">
											National Identification Number (NIN)
										</h4>
										<PatternFormat
											value={thisData?.result?.nin}
											format="#### ### ####"
											displayType="text"
											className="tw-bg-transparent tw-text-4xl tw-text-center tw-leading-5 tw-font-semibold tw-text-black Kum tw-tracking-[7%]"
											renderText={(value, props) => <h6 {...props}>{value}</h6>}
										/>
									</div>
								</div>
							</div>
							<div className="tw-bg-white tw-px-6 tw-py-6">
								<div>
									<h2 className="tw-text-4xl tw-text-center tw-font-bold Kum tw-text-black tw-uppercase">
										disclaimer
									</h2>
									<h5 className="tw-text-xl tw-font-normal tw-text-center Satisfy tw-text-[#767171]">
										Trust, but certify
									</h5>
									<p className="tw-text-base tw-font-medium tw-w-5/6 tw-mx-auto tw-text-[#1b1b1b] tw-text-center Kum">
										Kindly ensure each time this ID is presented, that you
										verify the credentials using a Government-APPROVED
										verification resource. The details on the front of this NIN
										Slip must EXACTLY match the verification result.
									</p>
									<h2 className="tw-text-3xl tw-text-black tw-text-center tw-font-bold tw-uppercase">
										caution
									</h2>
									<p className="tw-text-sm Kum tw-font-medium tw-text-[#1b1b1b] tw-text-center tw-w-11/12 tw-mx-auto">
										If this NIN was not issued to the person on the front of
										this document, please DO NOT attempt to scan, photocopy or
										replicate the personal data container herein.
									</p>
									<p className="tw-text-sm Kum tw-font-medium tw-text-[#1b1b1b] tw-text-center tw-w-11/12 tw-mx-auto">
										You are only permitted to scan the barcodes for the purpose
										of identity verification.
									</p>
									<p className="tw-text-sm Kum tw-font-medium tw-text-[#1b1b1b] tw-text-center tw-w-11/12 tw-mx-auto">
										The FEDERAL GOVERNMENT of NIGERIA assumes no responsibility
										if you accept any variance in the scan result or do not scan
										the 2D barcode overleaf
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="ms-auto d-flex align-items-center py-2">
				<button
					className="btn btn-primary1 d-block ms-auto"
					onClick={handlePrint}>
					Print Slip
				</button>
			</div>
		</>
	);
};

export const TransactionsData = ({
	state,
	setThisData,
	setState,
	type,
	criteria,
}) => {
	const { manageTransaction } = useContext(GlobalState),
		[isChecked, setIsChecked] = useState([]),
		{ page } = useParams(),
		[loading, setLoading] = useState(false);
	useEffect(() => {
		if (state) setIsChecked(state?.filter(item => item?.isCheckedFE));
	}, [state]);

	return (
		<div className="py-5">
			{page !== "dashboard" && isChecked?.length > 0 && (
				<div className="py-2 btn-group">
					{/* <Buttons
						title={"delete selected"}
						css="btn btn-danger-2 btn-danger2 py-2 py-md-3 text-capitalize Lexend"
						width={"auto"}
						loading={loading === "mark-delete"}
						onClick={async () => {
							setLoading("mark-delete");
							await manageTransaction(
								isChecked,
								type,
								criteria,
								"multiple",
								"mark-delete"
							);
							setLoading("");
						}}
					/> */}
					<Buttons
						title={"fail selected"}
						css="btn btn-primary1 py-2 py-md-3 text-capitalize Lexend mx-md-3"
						width={"auto"}
						loading={loading === "mark-fail"}
						onClick={async () => {
							setLoading("mark-fail");
							await manageTransaction(
								isChecked,
								type,
								criteria,
								"multiple",
								"mark-fail"
							);
							setLoading("");
						}}
					/>
					<Buttons
						title={"validate selected"}
						css="btn btn-success-2 btn-success2 py-2 py-md-3 text-capitalize Lexend"
						width={"auto"}
						loading={loading === "mark-success"}
						onClick={async () => {
							setLoading("mark-success");
							await manageTransaction(
								isChecked,
								type,
								criteria,
								"multiple",
								"mark-success"
							);
							setLoading("");
						}}
					/>
				</div>
			)}
			{/* <div className="row mx-0 py-3 bland">
				<div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend">
					s/n
				</div>
				<div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend d-none d-md-flex">
					ID
				</div>
				{page === "transactions" && (
					<div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend">
						type
					</div>
				)}
				<div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend d-none d-md-flex">
					usage
				</div>
				<div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend">
					recipient
				</div>
				<div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend d-none d-md-flex">
					channel
				</div>
				<div className="col my-auto text-uppercase fontReduce2 fw-bold Lexend d-none d-md-flex">
					date&time
				</div>
				<div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend">
					amount
				</div>
				<div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend">
					status
				</div>
			</div>
			{state?.length === 0 ? (
				<EmptyComponent subtitle={"User purchase history is empty"} />
			) : (
				state?.map((it, i) => (
					<div
						key={i}
						onClick={() => setThisData(it)}
						className={`row mx-0 bland2 border-bottom myCursor ${
							it?.isCheckedFE ? "list-group-item-secondary" : ""
						}`}>
						<div className="col my-auto text-capitalize fontReduce3 textTrunc py-3 py-md-4 w-100  h-100">
							
							<span className="fontInherit">{i + 1}</span>
						</div>
						<div className="col my-auto text-capitalize d-none d-md-flex fontReduce2 textTrunc py-3 py-md-4 ">
							{it?.item_id}
						</div>
						{page === "transactions" && (
							<div className="col my-auto text-capitalize fontReduce2 textTrunc py-3 py-md-4 ">
								{it?.type}
							</div>
						)}
						<div className="col my-auto fontReduce2 textTrunc py-3 py-md-4 d-none d-md-inline">
							{it?.type === "cables"
								? it?.properties?.packagename
									? it?.properties?.packagename
									: it?.properties?.type
								: it?.type === "airtime"
								? it?.properties?.network
								: it?.type === "electricity"
								? it?.properties?.disco
								: it?.type === "education"
								? it?.properties?.type
								: it?.type === "biz"
								? it?.properties?.name
								: it?.type === "data"
								? it?.transactionType?.replace(/_/g, " ")
								: ""}
						</div>

						<div className="col my-auto fontReduce2 textTrunc py-3 py-md-4 ">
							{it?.type === "cables" ? (
								it?.properties?.smartCardNo
							) : it?.type === "electricity" ? (
								it?.properties?.meterNo
							) : it?.type === "airtime" ? (
								it?.properties?.phone
							) : it?.type === "biz" ? (
								it?.properties?.result?.[0]?.rcNumber || it?.properties?.name
							) : it?.type === "education" ? (
								<>{it?.user?.lastName}</>
							) : it?.type === "data" ? (
								it?.properties?.phone
							) : (
								""
							)}
						</div>
						<div className="col my-auto d-none d-md-flex textTrunc py-3 py-md-4 text-uppercase">
							{it?.channel}
						</div>
						<div className="col my-auto d-none d-md-flex textTrunc py-3 py-md-4 fontReduce">
							{moment(it?.createdAt).format("DD/MM/YYYY hh:mm A")}
						</div>
						<div
							className={`col my-auto fontReduce2 textTrunc d-flex w-100 py-3 py-md-4`}>
							<span className="fontInherit d-none d-md-flex me-md-1">
								{it?.walletType === "CG_WALLET" ? "" : nairaSign}
							</span>{" "}
							<span className="fontInherit">
								{it?.walletType === "CG_WALLET" && it?.volume
									? numberWithCommas(Number(it?.volume).toFixed(2))
									: it?.properties?.amount
									? numberWithCommas(Number(it?.properties?.amount).toFixed(2))
									: 0}{" "}
								{it?.walletType === "CG_WALLET" ? "GB" : ""}
							</span>
						</div>
						<div
							className={`col textTrunc fontReduce3 text-uppercase my-auto py-3 py-md-4 ${
								it?.code === 200
									? "text-succcess2"
									: it?.code === 400
									? "text-warning2"
									: "text-danger2"
							}`}>
							{it?.code === 200
								? "successful"
								: it?.code === 400
								? "pending"
								: "failed"}
						</div>
					</div>
				))
			)} */}
			<TransactionTable setThisData={setThisData} currentItems={state} />
		</div>
	);
};

export const TransactionTable = ({ currentItems, setThisData }) => {
	let { nairaSign, numberWithCommas } = useContext(GlobalState),
		{ page } = useParams();
	return (
		<div className="tw-relative tw-overflow-x-auto tw-overflow-y-visible tw-min-h-52">
			<table className="tw-w-full tw-text-sm tw-text-left">
				<thead className="tw-text-xs tw-text-gray-700 tw-captalize bland tw-px-1">
					<tr>
						<th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
							S/N
						</th>
						<th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
							ID
						</th>
						{page === "transactions" && (
							<th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
								type
							</th>
						)}
						<th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
							usage
						</th>
						<th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
							recipient
						</th>
						<th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
							channel
						</th>
						<th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
							Amount
						</th>
						<th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
							Status
						</th>
						<th scope="col" className="tw-px-6 tw-py-6 Lexend tw-uppercase">
							date&time
						</th>
					</tr>
				</thead>
				<tbody>
					{currentItems?.map((it, i) => (
						<tr
							onClick={() => setThisData(it)}
							className={`bg-white tw-border-b tw-text-xs px-1 py-3 myCursor ${
								it?.isCheckedFE ? "list-group-item-secondary" : ""
							}`}
							key={i}>
							<td className="tw-w-4 tw-p-4">{i + 1}</td>
							<td className="tw-px-6 tw-py-6">{it?.item_id}</td>
							{page === "transactions" && (
								<td className="tw-px-6 tw-py-6 tw-capitalize">{it?.type}</td>
							)}
							<td className="tw-px-6 tw-py-6">
								{it?.type === "cables"
									? it?.properties?.packagename
										? it?.properties?.packagename
										: it?.properties?.type
									: it?.type === "airtime"
									? it?.properties?.network
									: it?.type === "electricity"
									? it?.properties?.disco
									: it?.type === "education"
									? it?.properties?.type
									: it?.type === "biz"
									? it?.properties?.name
									: ["data", "verify"]?.includes(it?.type)
									? it?.transactionType?.replace(/_/g, " ")
									: ""}
							</td>
							<td className="tw-px-6 tw-py-6">
								{it?.type === "cables" ? (
									it?.properties?.smartCardNo
								) : it?.type === "electricity" ? (
									it?.properties?.meterNo
								) : it?.type === "airtime" ? (
									it?.properties?.phone
								) : it?.type === "biz" ? (
									it?.properties?.result?.[0]?.rcNumber || it?.properties?.name
								) : it?.type === "verify" ? (
									it?.properties?.verify || it?.properties?.name
								) : it?.type === "education" ? (
									<>
										{it?.user?.lastName} {it?.user?.firstName}
									</>
								) : it?.type === "data" ? (
									it?.properties?.phone
								) : (
									""
								)}
							</td>
							<td className="tw-px-6 tw-py-6 tw-uppercase">{it?.channel}</td>
							<td className="tw-px-6 tw-py-6">
								<span className="flex items-center">
									<span className="fontInherit">
										{it?.walletType === "CG_WALLET" ? "" : nairaSign}
									</span>{" "}
									<span className="fontInherit">
										{it?.walletType === "CG_WALLET" && it?.volume
											? numberWithCommas(Number(it?.volume).toFixed(2))
											: it?.properties?.amount
											? numberWithCommas(
													Number(it?.properties?.amount).toFixed(2)
											  )
											: 0}{" "}
										{it?.walletType === "CG_WALLET" ? "GB" : ""}
									</span>
								</span>
							</td>
							<td
								className={`tw-px-6 tw-py-6 tw-uppercase ${
									it?.code === 200
										? "text-succcess2"
										: it?.code === 400
										? "text-warning2"
										: "text-danger2"
								} tw-font-semibold`}>
								{it?.code === 200
									? "successful"
									: it?.code === 400
									? "pending"
									: "failed"}
							</td>
							<td className="tw-px-6 tw-py-6">
								{moment(it?.createdAt).format("DD/MM/YYYY hh:mm A")}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default TransactionsFolder;

export const TopFolder = ({ setSubActive }) => {
	let { setStateName, wallet, numberWithCommas, nairaSignNeutral } =
		useContext(GlobalState);
	useEffect(() => {
		setStateName("Transactions history");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let usersArr = [
		{
			icon: icon2,
			name: "Today's Transactions",
			number: `${nairaSignNeutral}${numberWithCommas(
				Number(wallet?.wallet_details?.transactions?.day || 0).toFixed(2)
			)}`,
			color:
				"linear-gradient(90deg, rgba(228, 51, 105, 0.7) 16.14%, rgba(194, 14, 25, 0.7) 101.45%)",
		},
		{
			icon: icon3,
			name: `${moment().format("MMMM")}'s Transactions`,
			number: `${nairaSignNeutral}${numberWithCommas(
				Number(wallet?.wallet_details?.transactions?.month || 0).toFixed(2)
			)}`,
			color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
		},
		{
			icon: icon1,
			name: "Total Transactions",
			number: `${nairaSignNeutral}${numberWithCommas(
				Number(wallet?.wallet_details?.transactions?.total || 0).toFixed(2)
			)}`,
			color: "linear-gradient(90.18deg, #84C7DB -52.19%, #377FB6 81.92%)",
		},
	];
	// let usersArr2 = [
	// 	{
	// 		icon: icon2,
	// 		name: "Today's Transactions",
	// 		number: `${nairaSignNeutral}${
	// 			wallet?.wallet_details?.transactions?.agent?.day
	// 				? numberWithCommas(wallet?.wallet_details?.transactions?.agent?.day)
	// 				: 0
	// 		}`,
	// 		color:
	// 			"linear-gradient(90deg, rgba(228, 51, 105, 0.7) 16.14%, rgba(194, 14, 25, 0.7) 101.45%)",
	// 	},
	// 	{
	// 		icon: icon3,
	// 		name: `${moment().format("MMMM")}'s Transactions`,
	// 		number: `${nairaSignNeutral}${
	// 			wallet?.wallet_details?.transactions?.agent?.month
	// 				? numberWithCommas(wallet?.wallet_details?.transactions?.agent?.month)
	// 				: 0
	// 		}`,
	// 		color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
	// 	},
	// 	{
	// 		icon: icon1,
	// 		name: "Total Transactions",
	// 		number: `${nairaSignNeutral}${
	// 			wallet?.wallet_details?.transactions?.agent?.total
	// 				? numberWithCommas(wallet?.wallet_details?.transactions?.agent?.total)
	// 				: 0
	// 		}`,
	// 		color: "linear-gradient(90.18deg, #84C7DB -52.19%, #377FB6 81.92%)",
	// 	},
	// ];

	return <ThreeBoxBar list={usersArr} setSubActive={setSubActive} />;
};

export let NewPaginate = ({ state, setThisData, setState, type, criteria }) => {
	let [range, setRange] = useState(10),
		{
			transactions,
			airtimes,
			data,
			electricity,
			education,
			cables,
			biz,
			nairaSignNeutral,
			numberWithCommas,
		} = useContext(GlobalState);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	const currentItems = state.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(state.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % state.length;
		setItemOffset(newOffset);
	};

	let [down, setDown] = useState(null),
		[dataset, setDataset] = useState(null),
		{ page } = useParams(),
		[columnUse, setColumnUse] = useState(null),
		handleDownloadExcel = () => {
			setDown(true);
			downloadExcel({
				fileName: `${
					process.env.REACT_APP_NAME
				} ${type?.toUpperCase()} History ${moment().format("DDMMYYYYHHmmA")}`,
				sheet: `${type?.toUpperCase()} History`,
				tablePayload: {
					header: [
						"S/N",
						"ID",
						"Type",
						"Usage",
						"Recipeint",
						"Channel",
						"Amount",
						"API Response",
						"Status",
						"Date&time",
					],
					//   // accept two different data structures
					body: dataset,
				},
			});
			setDown(false);
		};

	useEffect(() => {
		if (type === "all") setColumnUse(transactions);
		if (type === "airtime") setColumnUse(airtimes);
		if (type === "data") setColumnUse(data);
		if (type === "electricity") setColumnUse(electricity);
		if (type === "cables") setColumnUse(cables);
		if (type === "education") setColumnUse(education);
		if (type === "biz") setColumnUse(biz);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type, transactions, airtimes, data, electricity, education, cables, biz]);
	// console.log({ columnUse, type });
	useEffect(() => {
		if (columnUse?.all?.length > 0 && type) {
			let userData = [];
			for (let i = 0; i < columnUse?.all?.length; i++) {
				let it = columnUse?.all?.[i];
				userData?.push({
					index: i + 1,
					id: columnUse?.all?.[i]?.item_id,
					type: columnUse?.all?.[i]?.type,
					usage:
						it?.type === "cables"
							? it?.properties?.packagename
								? it?.properties?.packagename
								: it?.properties?.type
							: it?.type === "airtime"
							? it?.properties?.network
							: it?.type === "electricity"
							? it?.properties?.disco
							: it?.type === "education"
							? it?.properties?.type
							: it?.type === "biz"
							? it?.properties?.name
							: it?.type === "data"
							? it?.transactionType?.replace(/_/g, " ")
							: "",
					recipient:
						it?.type === "cables" ? (
							it?.properties?.smartCardNo
						) : it?.type === "electricity" ? (
							it?.properties?.meterNo
						) : it?.type === "airtime" ? (
							it?.properties?.phone
						) : it?.type === "biz" ? (
							it?.properties?.result?.[0]?.rcNumber || it?.properties?.name
						) : it?.type === "education" ? (
							<>
								{it?.user?.lastName} {it?.user?.firstName}
							</>
						) : it?.type === "data" ? (
							it?.properties?.phone
						) : (
							""
						),
					channel: columnUse?.all?.[i]?.channel,
					amount: `${it?.walletType === "CG_WALLET" ? "" : nairaSignNeutral} ${
						it?.walletType === "CG_WALLET" && it?.volume
							? numberWithCommas(Number(it?.volume).toFixed(2))
							: it?.properties?.amount
							? numberWithCommas(Number(it?.properties?.amount).toFixed(2))
							: 0
					} ${it?.walletType === "CG_WALLET" ? "GB" : ""}`,
					response: it?.statusText,
					status:
						it?.code === 200
							? "successful"
							: it?.code === 400
							? "pending"
							: "failed",
					createdAt: moment(columnUse?.all?.[i]?.createdAt).format(
						"Do MMMM, YYYY. hh:mm A"
					),
				});
			}
			setDataset(userData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columnUse?.all, type]);

	return (
		<>
			<div className="d-flex justify-content-between align-items-center w-100">
				<div className="w50 w-50">
					<MainRanger range={range} setRange={setRange} />
				</div>
				{columnUse?.all?.length > 0 && page !== "dashboard" && (
					<>
						<div className="d-flex justify-content-end align-items-center w-50 w50">
							<Buttons
								loading={down}
								onClick={handleDownloadExcel}
								title="download excel"
								css="btn-primary1 text-capitalize py-2 py-md-3 px-3 pm-md-5"
								width="btn"
							/>
						</div>
					</>
				)}
			</div>
			<TransactionsData
				state={currentItems}
				setThisData={setThisData}
				setState={setState}
				type={type}
				criteria={criteria}
			/>
			<MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
		</>
	);
};

export const MainPaginate = ({ handlePageClick, pageCount }) => (
	<ReactPaginate
		breakLabel="..."
		nextLabel=">"
		onPageChange={handlePageClick}
		pageRangeDisplayed={5}
		pageCount={pageCount}
		previousLabel="<"
		renderOnZeroPageCount={null}
		className="list-unstyled d-flex align-items-center justify-content-end py-3"
		pageClassName="mx-1 p-2 border rounded tex-capitalize text-decoration-none"
		previousClassName="text-decoration-none d-none"
		nextClassName="text-decoration-none d-none"
		activeClassName="list-group-item-primary"
	/>
);

export const MainRanger = ({ range, setRange }) => {
	let rangeArr = [10, 50, 100, 200, 500, 1000];

	return (
		<div className="py-3">
			<div className="col-3 col-md-1">
				<select
					className="form-control py-2 form-select"
					name="range"
					value={range}
					onChange={e => {
						setRange(Number(e.target.value));
					}}>
					{rangeArr?.map((item, i) => (
						<option key={i} value={item}>
							{item}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};

export const FindDetails = ({
	state,
	textChange,
	handleFind,
	loading,
	isOpen,
	back,
	notype,
	filter,
}) => {
	let { network, category } = useContext(GlobalState);
	return (
		<>
			<ModalComponents
				isOpen={isOpen}
				toggle={back}
				title={`${filter ? "Filter" : "Query"} selection`}>
				<>
					{!notype && (
						<div className="mb-4">
							<label htmlFor="Type">Type</label>
							<select
								className="form-control py-3 py-md-4 text-capitalize form-select"
								name="type"
								placeholder="Type"
								value={state?.type}
								onChange={textChange("type")}
								id="type">
								<option value="">select type</option>
								<option value="data">Data</option>
								<option value="airtime">Airtime</option>
								<option value="cables">Cables subscription</option>
								<option value="electricity">Electricity</option>
								<option value="biz">Biz Verification</option>
								<option value="education">Education</option>
							</select>
						</div>
					)}
					<div className="mb-4">
						<label htmlFor="Network">Network</label>
						<select
							className="form-control py-3 py-md-4 text-capitalize form-select"
							name="network"
							placeholder="Network"
							value={state?.network}
							onChange={textChange("network")}
							id="network">
							<option value="">select network</option>
							{network?.data?.map((item, i) => (
								<option value={item?.name} key={i}>
									{item?.name}
								</option>
							))}
						</select>
					</div>
					<div className="mb-4">
						<label htmlFor="Category">Category</label>
						<select
							className="form-control py-3 py-md-4 text-capitalize form-select"
							name="category"
							placeholder="Category"
							value={state?.category}
							onChange={textChange("category")}
							id="category">
							<option value="">select category</option>
							{category?.data?.map((item, i) => (
								<option value={item?.categoryId} key={i}>
									{item?.name}
								</option>
							))}
						</select>
					</div>
					{filter && (
						<>
						<div className="mb-4">
							<label htmlFor="Status">Status</label>
							<select
								className="form-control py-3 py-md-4 text-capitalize form-select"
								name="status"
								placeholder="Status"
								value={state?.status}
								onChange={textChange("status")}
								id="status">
								<option value="">select status</option>
								<option value={200}>Successful</option>
								<option value={400}>Pending</option>
								<option value={300}>Failed</option>
							</select>
						</div>
						<DateFilter state={state} textChange={textChange} />
						</>
					)}
					<Buttons
						title={filter ? "Filter" : "Query"}
						css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
						width={"w-50"}
						onClick={handleFind}
						loading={loading}
						style={{ borderRadius: "30px" }}
					/>
				</>
			</ModalComponents>
		</>
	);
};


export const DateFilter = ({ state, textChange }) => {
	return (
		<>
			<div className="mb-4">
				<label htmlFor="Start Date">Start Date</label>
				<input
					className="form-control py-3 py-md-4 text-capitalize"
					name="startDate"
					type="datetime-local"
					placeholder="Start Date"
					value={state?.startDate}
					onChange={textChange("startDate")}
					id="startDate"
				/>
			</div>
			{state?.startDate && (
				<div className="mb-4">
					<label htmlFor="End Date">End Date</label>
					<input
						className="form-control py-3 py-md-4 text-capitalize"
						name="endDate"
						type="datetime-local"
						placeholder="End Date"
						value={state?.endDate}
						onChange={textChange("endDate")}
						id="endDate"
					/>
				</div>
			)}
		</>
	);
};