import {
	ADD_DATA,
	ADD_DATA_FAIL,
	DELETE_TRANSACTION,
	DELETE_TRANSACTION_FAIL,
	GET_ALL_DATA,
	GET_DATA,
	GET_DATA_FAIL,
	GET_DATA_TO_BUY,
	LOGOUT,
	SEARCH_DATA,
	SEARCH_DATA_FAIL,
	SEARCH_DATA_LOADING,
	SEARCH_DATA_RELOAD,
	UPDATE_TRANSACTION,
} from "../Actions/ActionTypes";
import { DeleteData, EditData } from "./AuthReducer";

const initialState = {
	isLoading: false,
	data: [],
	isAdded: false,
	isUpdated: false,
	paginate: null,
	isDeleted: null,
	isFound: null,
	searchLoading: null,
	mainSearch: [],
	search: "",
	search_paginate: null,
	dataToBuy: null,
};

const DataMainReducer = (state = initialState, action) => {
	const { type, payload } = action;
	let data = payload?.data ? payload?.data : payload;

	switch (type) {
		case GET_DATA_TO_BUY:
			return {
				...state,
				dataToBuy: data ? data : [],
			};
		case SEARCH_DATA:
			return {
				...state,
				isFound: true,
				searchLoading: false,
				mainSearch: action?.search === state?.search ? data : state?.mainSearch,
				search_paginate:
					action?.search === state?.search
						? payload?.paginate
						: state?.search_paginate,
			};
		case SEARCH_DATA_FAIL:
			return {
				...state,
				searchLoading: false,
				mainSearch: state.mainSearch,
				search_paginate: state.search_paginate,
				isFound: false,
			};
		case SEARCH_DATA_LOADING:
			return {
				...state,
				search: action.search,
				searchLoading: true,
			};
		case SEARCH_DATA_RELOAD:
			return {
				...state,
				isFound: false,
				searchLoading: false,
			};
		case GET_DATA:
			return {
				...state,
				isLoading: false,
				data: data ? data : [],
				paginate: payload?.paginate,
			};
		case GET_ALL_DATA:
			return {
				...state,
				all: data || [],
			};
		case GET_DATA_FAIL:
			return {
				...state,
				isLoading: false,
			};
		case ADD_DATA:
			return {
				...state,
				isAdded: true,
				data: [data, ...state?.data],
				paginate: {
					...state?.paginate,
					result: state?.paginate?.result + 1,
					total: state?.paginate?.total + 1,
				},
			};
		case DELETE_TRANSACTION:
			return {
				...state,
				mainSearch: DeleteData(state.mainSearch, payload),
				data: DeleteData(state.data, payload),
				isDeleted: true,
				paginate: {
					...state?.paginate,
					result: state?.paginate?.result - 1,
					total: state?.paginate?.total - 1,
				},
			};
		case DELETE_TRANSACTION_FAIL:
			return {
				...state,
				isDeleted: false,
			};
		case ADD_DATA_FAIL:
			return {
				...state,
				isLoading: false,
				isUpdated: false,
				isAdded: false,
				isDeleted: false,
			};
		case UPDATE_TRANSACTION:
			return {
				...state,
				isUpdated: true,
				data: EditData(state?.data, data),
				mainSearch: EditData(state?.mainSearch, data),
			};
		case LOGOUT:
			return initialState;
		default:
			return state;
	}
};

export default DataMainReducer;
