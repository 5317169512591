import { useState } from "react";
import AppLogo from "../app-logo";
import { useNavigate } from "react-router-dom";
import ScrollIntoView from "react-scroll-into-view";
import { MdClose } from "react-icons/md";

const links = [
  { id: 1, name: "Home", url: "#home" },
  { id: 2, name: "About", url: "#about" },
  { id: 3, name: "FAQ", url: "#faq" },
  { id: 4, name: "Contact", url: "#contact" },
];

const Nav = () => {
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState(false);
  const toggleNav = () => {
    setShowNav(!showNav);
  };
  const closeNav = () => {
    setShowNav(false);
  };
  return (
    <nav class="tw-fixed tw-w-full tw-z-20 tw-top-0 tw-backdrop-blur-md tw-left-0 relative">
      <div class="container tw-h-24 tw-flex tw-items-center tw-justify-between">
        <div className="tw-flex tw-items-center tw-gap-24">
          <div className="">
            <AppLogo />
          </div>
        </div>
        <div
          class={`tw-items-center tw-justify-between tw-hidden md:tw-flex tw-w-auto tw-order-1`}
          id="navbar-sticky"
        >
          <ul
            className={`tw-flex tw-space-x-10 tw-mt-0 tw-border-0 tw-text-black tw-font-medium`}
          >
            {links.map((link) => (
              <ScrollIntoView selector={link.url}>
                <li className="tw-block general tw-uppercase tw-cursor-pointer tw-pl-3 tw-text-black tw-pr-4 md:text-xl tw-rounded md:tw-bg-transparent tw-decoration-transparent md:tw-text-black md:tw-p-0 ">
                  <a
                    href={link.url}
                    className="tw-block general tw-uppercase tw-cursor-pointer tw-pl-3 tw-text-black tw-pr-4 md:text-xl tw-rounded md:tw-bg-transparent tw-decoration-transparent md:tw-text-black md:tw-p-0"
                  >
                    {link.name}
                  </a>
                </li>
              </ScrollIntoView>
            ))}
            <li>
              <button
                type="button"
                className="tw-text-black tw-bg-[#2F75FD] tw-mt-4 focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-300 tw-font-medium tw-rounded-full tw-text-md tw-px-4 tw-py-2 tw-text-center tw-mr-3 md:tw-mr-0 sm:tw-hidden tw-block"
                onClick={() => navigate("/register")}
              >
                Get started
              </button>
            </li>
          </ul>
        </div>
        <div className="tw-flex md:tw-order-2 tw-items-center tw-gap-5">
          <a
            style={{
              textDecoration: "none !important",
            }}
            href="https://play.google.com/store/apps/details?id=com.ng.honourworld"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="tw-h-14 md:tw-w-40 tw-w-36 tw-bg-[#06EF79] tw-rounded-full tw-block md:tw-text-base tw-text-sm general tw-font-bold">
              DOWNLOAD APP
            </button>
          </a>
          <button
            type="button"
            className="tw-text-black general tw-bg-white tw-border tw-border-black tw-uppercase focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-300 tw-font-semibold tw-rounded-full md:tw-text-xl md:tw-w-36 tw-w-24 tw-h-12 tw-text-center tw-mr-3 md:tw-mr-0 tw-block"
            onClick={() => navigate("/login")}
          >
            Log in
          </button>
          {/* <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            className={`tw-inline-flex tw-items-center tw-p-2 tw-text-sm tw-text-black tw-rounded-lg md:tw-hidden focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-200`}
            aria-controls="navbar-sticky"
            aria-expanded="false"
            onClick={toggleNav}
          >
            <span class="sr-only">Open main menu</span>
            <svg
              class="tw-w-6 tw-h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button> */}
        </div>
      </div>
      {/* mobile nav */}
      <aside
        id="logo-sidebar"
        className={`tw-fixed tw-top-0 tw-left-0 md:tw-hidden tw-z-40 tw-w-full tw-h-screen tw-transition-transform tw-bg-white  ${
          showNav
            ? "tw-translate-x-0"
            : "-tw-translate-x-full md:tw-translate-x-0"
        }`}
        aria-label="Sidebar"
      >
        <div class="tw-h-full tw-px-3 tw-py-4 tw-overflow-y-auto">
          <ul className="space-y-2 font-medium">
            <li className="tw-mb-8 md:hidden tw-flex tw-items-center tw-justify-between">
              <AppLogo />
              <div className="tw-text-black tw-mr-8" onClick={closeNav}>
                <MdClose size={30} />
              </div>
            </li>
            {links.map((link) => (
              <ScrollIntoView selector={link.url} onClick={toggleNav}>
                <li className="tw-block tw-py-8 tw-pl-3 tw-pr-4 tw-text-black tw-text-4xl tw-rounded md:tw-bg-transparent tw-decoration-transparent md:tw-p-0 ">
                  {link.name}
                </li>
              </ScrollIntoView>
            ))}
            <li>
              <button
                type="button"
                className="tw-text-white tw-bg-[#2F75FD] tw-mt-4 hover:tw-bg-gray-100 focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-300 tw-font-medium tw-rounded-3xl tw-text-2xl tw-px-8 tw-py-4 tw-text-center tw-mr-3 md:tw-mr-0 sm:tw-hidden tw-block"
                onClick={() => navigate("/login")}
              >
                Login
              </button>
            </li>
          </ul>
        </div>
      </aside>
    </nav>
  );
};

export default Nav;
