import React from "react";
import Expenses from "../../Assets/expenses.svg";
const Users = () => {
	return <div>Users</div>;
};

export default Users;

export const ThreeBoxBar = ({ list, setSubActive }) => {
	return (
		<>
			<div className="lg:tw-w-3/4 tw-mx-auto tw-mb-16  tw-py-6 tw-gap-8 tw-grid lg:tw-grid-cols-3">
				{list?.map((item, index) => (
					<div
						key={index}
						onClick={
							setSubActive
								? () => {
										setSubActive(index);
								  }
								: () => {}
						}
						className="tw-flex tw-items-center tw-cursor-pointer hover:tw-shadow-inner tw-p-4 tw-justify-between tw-h-32 tw-shadow-xl">
						<img src={Expenses} alt="" className="tw-h-8" />
						<div className="">
							<h1 className="tw-text-3xl tw-font-bold tw-text-black tw-capitalize tw-text-right">
								{item.number}
							</h1>
							<p className="tw-text-xl tw-font-bold tw-text-black tw-text-right">
								{item.name}
							</p>
						</div>
					</div>
				))}
			</div>
			{/* <div className="lg:tw-w-3/4 tw-mx-auto tw-py-6 tw-gap-8 tw-grid lg:tw-grid-cols-3">
        {list?.map((item, index) => (
          <div
            onClick={
              setSubActive
                ? () => {
                    setSubActive(index);
                  }
                : () => {}
            }
            className="col-6 col-md-4 productCard"
            key={index}
          >
            <div
              className="row mx-0 p-3 eachProduct rounded20 text-white h-100"
              onClick={() => (item?.link ? navigate(item?.link) : {})}
              style={{
                background: item?.color,
              }}
            >
              <div className="col my-auto d-none d-md-flex">
                <img src={item?.icon} className="img-fluid" alt="Icon" />
              </div>
              <div className="">
                <p className="tw-text-black tw-text-base">{item?.number}</p>
                <h6 className="tw-text-xl tw-text-black tw-font-semibold">
                  {item?.name}
                </h6>
              </div>
            </div>
          </div>
        ))}
      </div> */}
		</>
	);
};
