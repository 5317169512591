import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";

import { FaArrowUp } from "react-icons/fa";

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;

    setIsVisible(scrollY > 200);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {isVisible && (
        <button
          className="tw-fixed tw-bottom-6 tw-z-[1000] tw-flex tw-justify-center tw-items-center tw-right-6 tw-bg-[#06EF79] tw-rounded tw-h-12 tw-w-12"
          onClick={scrollToTop}
        >
          <IconContext.Provider value={{ color: "#fff" }}>
            {" "}
            <FaArrowUp size={20} />
          </IconContext.Provider>
        </button>
      )}
    </div>
  );
};

export default BackToTopButton;
