import HomeOneNav from "../../Components/home-one/home-one-nav.component";

import { FaArrowUp, FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

import "./home-one.style.css";
import AppLogo from "../../Components/app-logo";

const features = [
	{
		title: "Secure Transactions",
		text: "We take the security of your account seriously, we are committed to preventing data loss or leak.",
		icon: "vic1.png",
	},
	{
		title: "Fast Delivery/Response",
		text: "Our data delivery is super-fast. We understand that you need data and you need it NOW!",
		icon: "vic2.png",
	},
	{
		title: "Optimum Satisfaction",
		text: `Periodically, ${process.env.REACT_APP_NAME} offers the best discounts and special deals on all of our services, including data, airtime, and cable subscriptions. Stay tuned for updates on our special offers and take advantage of the opportunity to save on these services.`,
		icon: "vic3.png",
	},
	{
		title: "24/7 Customer Support",
		text: `${process.env.REACT_APP_NAME} has a team of well-trained customer support agents available 24/7 to assist you with any issues you may have. We offer multiple channels of communication to ensure that you can easily reach us and receive prompt assistance.`,
		icon: "vic4.png",
	},
];

const HomeOne = () => {
	return (
		<div className="home-one">
			<header>
				<div className="hero-section text-white">
					<div className="bg-rectangle"></div>
					<HomeOneNav />
					<div className="container py-4 position-relative mt-5 mt-md-0">
						<div className="row g-4">
							<div
								data-aos="fade-right"
								data-aos-duration="2000"
								className="hero-texts text-white col-md-6 align-self-center">
								{" "}
								<p className="fs-1 text-4xl text-capitalize fw-bold mb-0">
									buy data easily
								</p>
								<p
									data-aos="fade-in"
									data-aos-easing="ease-in-out"
									className="fw-bold fs-3 text-xl">
									GLO, MTN, 9Mobile, Airtel
								</p>
								<p className="m3  -5">
									In a few clicks, buy data to keep surfing the internet. You
									can buy whatever size of data plan for whichever network you
									desire. Get Started!
								</p>
								<div className="mt-5">
									<button
										type="button"
										class="btn btn-light me-4 fw-semibold"
										style={{ height: "40px", padding: "0 40px" }}>
										<p className="gradient-text m-0  text-uppercase">
											get started
										</p>
									</button>
								</div>
							</div>
							<div data-aos="zoom-in-up" className="hero-img col-md-6">
								<img
									src={require("../../Assets/home-one-hero.png")}
									className="w-100 mx-auto"
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</header>
			<section className="py-5">
				<div className="container">
					<div className="row g-4">
						<div className="col-md-4">
							<span
								data-aos="fade-up"
								data-aos-duration="3000"
								className="gradient-text h1 fw-bold text-capitalize">
								the best features our users are enjoying
							</span>
							<p
								data-aos="fade-up"
								data-aos-duration="3000"
								className="small mt-3">
								Sign in today to enjoy our unlimited offers!
							</p>
							<div data-aos="zoom-in-up" className="d-flex mt-5">
								<img
									src={require("../../Assets/home-one-feature.png")}
									alt=""
									// className="mx-auto w-100"
									style={{ height: "300px", width: "auto" }}
								/>
							</div>
						</div>
						<div className="col-md-8 align-self-center">
							<div
								data-aos="fade-up"
								data-aos-duration="3000"
								className="row g-4">
								{features.map((feature, idx) => (
									<div
										className="col-sm-6 d-flex justify-content-center"
										key={idx}>
										<div
											class="card mx-auto border-0"
											style={{ width: "18rem" }}>
											<div className="card-body mb-4">
												<img
													src={require(`../../Assets/${feature.icon}`)}
													alt=""
													style={{ height: "32px" }}
												/>
											</div>
											<div class="card-body">
												<h5 class="card-title fw-bold mb-2">{feature.title}</h5>
												<p class="card-text small">{feature.text}</p>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="py-5 about">
				<div className="container">
					<div className="row">
						<div
							data-aos="fade-up"
							data-aos-duration="3000"
							className="col-md-7 align-self-center">
							<p className="fw-bold gradient-text text-2xl gradient-text text-uppercase">
								About us
							</p>
							<div className="p-3 border-start border-3 border-dark">
								<p className="m-0">
									{process.env.REACT_APP_NAME} is a convenient platform for
									purchasing data and airtime, as well as obtaining cable and
									Waec/Neco subscriptions. We offer easy and rapid airtime
									top-up services at competitive rates, and we are committed to
									delivering prompt and timely service for all transactions. Our
									well-trained customer support team is available 24/7 to assist
									you with any issues or questions you may have. We strive to
									provide the best value for every transaction and make it easy
									for you to access the services you need.
								</p>
							</div>
						</div>
						<div data-aos="zoom-in-up" className="col-md-5 d-flex">
							<img
								src={require("../../Assets/home-one-about-us.png")}
								alt=""
								className="mx-auto"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="services py-5">
				<div
					data-aos="fade-up"
					data-aos-duration="3000"
					className="text-center mb-4">
					<span className="gradient-text h1 fw-bold">Our services</span>
					<p className="text-white mt-3 fs-5 small">
						Yes, we got you covered, With {process.env.REACT_APP_NAME} Enjoy a
						easy and fast data delivery with Optimal security <br /> and also
						enjoy the best offers and discount sales on all our products
						periodically.
					</p>
				</div>
				<div className="container mt-5">
					<div className="row g-4">
						<div
							data-aos="fade-up"
							data-aos-duration="3000"
							className="col-sm-6 col-md-4">
							<div
								class="card bg-transparent text-white mx-auto"
								style={{ maxWidth: "24rem" }}>
								<div class="card-body">
									<div className="d-flex justify-content-between align-items-center mb-2">
										<div>
											<div className="border border-white w-25"></div>
											<h5 class="card-title mt-5">Airtime Services</h5>
										</div>
										<span className="me-4">
											<img
												src={require("../../Assets/vec1.png")}
												alt=""
												style={{ height: "20px" }}
											/>
										</span>
									</div>
									<p class="card-text">
										{process.env.REACT_APP_NAME} offers convenient and rapid
										airtime top-up services at competitive rates. Our low
										charges and quick recharge services make it easy for
										customers to top up their airtime.
									</p>
									<div className="d-flex justify-content-end">
										<FaArrowUp size="18px" />
									</div>
								</div>
							</div>
						</div>
						<div
							data-aos="fade-up"
							data-aos-duration="3000"
							className="col-sm-6 col-md-4">
							<div
								class="card bg-transparent text-white mx-auto"
								style={{ maxWidth: "24rem" }}>
								<div class="card-body">
									<div className="d-flex justify-content-between align-items-center mb-2">
										<div>
											<div className="border border-white w-25"></div>
											<h5 class="card-title mt-5">Data Services</h5>
										</div>
										<span className="me-4">
											<img
												src={require("../../Assets/vec2.png")}
												alt=""
												style={{ height: "20px" }}
											/>
										</span>
									</div>
									<p class="card-text">
										{process.env.REACT_APP_NAME} is a reliable and affordable
										platform for purchasing affordable data bundles and airtime
										top-ups for any Nigerian network. We offer competitive
										pricing for data plans from Airtel, MTN, 9mobile, and Glo.
									</p>
									<div className="d-flex justify-content-end">
										<FaArrowUp size="18px" />
									</div>
								</div>
							</div>
						</div>
						<div
							data-aos="fade-up"
							data-aos-duration="3000"
							className="col-sm-6 col-md-4">
							<div
								class="card bg-transparent text-white mx-auto"
								style={{ maxWidth: "24rem" }}>
								<div class="card-body">
									<div className="d-flex justify-content-between align-items-center mb-2">
										<div>
											<div className="border border-white w-25"></div>
											<h5 class="card-title mt-5">Education</h5>
										</div>
										<span className="me-4">
											<img
												src={require("../../Assets/vec3.png")}
												alt=""
												style={{ height: "20px" }}
											/>
										</span>
									</div>
									<p class="card-text">
										{process.env.REACT_APP_NAME} is your go-to source for
										various examination scratch cards, including those for Waec,
										Neco, and Nabteb. We take pride in providing top-quality
										service and aim to make our website your go-to destination
										after your first experience with us.
									</p>
									<div className="d-flex justify-content-end">
										<FaArrowUp size="18px" />
									</div>
								</div>
							</div>
						</div>
						<div
							data-aos="fade-up"
							data-aos-duration="3000"
							className="col-sm-6 col-md-4">
							<div
								class="card bg-transparent text-white mx-auto"
								style={{ maxWidth: "24rem" }}>
								<div class="card-body">
									<div className="d-flex justify-content-between align-items-center mb-2">
										<div>
											<div className="border border-white w-25"></div>
											<h5 class="card-title mt-5">Cable Subscriptions</h5>
										</div>
										<span className="me-4">
											<img
												src={require("../../Assets/vec5.png")}
												alt=""
												style={{ height: "20px" }}
											/>
										</span>
									</div>
									<p class="card-text">
										At {process.env.REACT_APP_NAME}, we offer discounted rates
										for renewing your DSTV, GOTV, and Startimes subscriptions,
										allowing you to save money on your cable service. Our
										convenient access to subscription renewal saves you time and
										effort in obtaining your cable service.
									</p>
									<div className="d-flex justify-content-end">
										<FaArrowUp size="18px" />
									</div>
								</div>
							</div>
						</div>
						<div
							data-aos="fade-up"
							data-aos-duration="3000"
							className="col-sm-6 col-md-4">
							<div
								class="card bg-transparent text-white mx-auto"
								style={{ maxWidth: "24rem" }}>
								<div class="card-body">
									<div className="d-flex justify-content-between align-items-center mb-2">
										<div>
											<div className="border border-white w-25"></div>
											<h5 class="card-title mt-5">TV Subscriptions</h5>
										</div>
										<span className="me-4">
											<img
												src={require("../../Assets/vec6.png")}
												alt=""
												style={{ height: "20px" }}
											/>
										</span>
									</div>
									<p class="card-text">
										At {process.env.REACT_APP_NAME}, we offer discounted rates
										for renewing your DSTV, GOTV, and Startimes subscriptions,
										allowing you to save money on your cable service. Our
										convenient access to subscription renewal saves you time and
										effort in obtaining your cable service.
									</p>
									<div className="d-flex justify-content-end">
										<FaArrowUp size="18px" />
									</div>
								</div>
							</div>
						</div>
						<div
							data-aos="fade-up"
							data-aos-duration="3000"
							className="col-sm-6 col-md-4">
							<div
								class="card bg-transparent text-white mx-auto"
								style={{ maxWidth: "24rem" }}>
								<div class="card-body">
									<div className="d-flex justify-content-between align-items-center mb-2">
										<div>
											<div className="border border-white w-25"></div>
											<h5 class="card-title mt-5">Electricity Payment</h5>
										</div>
										<span className="me-4">
											<img
												src={require("../../Assets/vec7.png")}
												alt=""
												style={{ height: "20px" }}
											/>
										</span>
									</div>
									<p class="card-text">
										Do you want to stay on top of your finances, then make you
										can make use of our pocket-friendly electricity payment
										feature. It saves more time and money with our streamlined
										payment tools.
									</p>
									<div className="d-flex justify-content-end">
										<FaArrowUp size="18px" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="py-5 about">
				<div className="container">
					<div className="row g-5">
						<div
							data-aos="fade-up"
							data-aos-duration="3000"
							className="col-md-6">
							<p className="gradient-text h1 fw-bold">Benefits</p>
							<p className="small">
								Take control of your financial future with our easy-to-use
								budgeting and friendly tools. Enjoy peace of mind with our 24/7
								customer support and expert guidance.
							</p>
							<div
								className="d-flex align-items-center justify-content-between"
								style={{ maxWidth: "400px" }}>
								<div>
									<p className="h3 fw-bold mb-1">
										100k<span>+</span>
									</p>
									<small>user active</small>
								</div>
								<div>
									<p className="h3 fw-bold mb-1">10+</p>
									<small>country</small>
								</div>
								<div>
									<p className="h3 fw-bold mb-1">$40M+</p>
									<small>transactions</small>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<img
								src={require("../../Assets/map.png")}
								alt=""
								// style={{ height: "300px" }}
								width="100%"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="py-5">
				<div
					data-aos="fade-up"
					data-aos-duration="3000"
					className="text-center">
					<span className="gradient-text h1 fw-bold">Testimonials</span>
					<p className="mt-3 fs-5">Hear Our Customers Speak</p>
				</div>
				<div className="container">
					<div className="row g-5 mt-4">
						<div className="col-md-4">
							<div
								data-aos="zoom-in-up"
								className="testimony text-white p-4 rounded-3">
								<div className="icon">
									<FaQuoteLeft />
								</div>
								<div className="px-3">
									<p className="text-white">
										Ever since i started business with{" "}
										{process.env.REACT_APP_NAME} it has been all smiles...they
										have the best customer service team ever...prompt and fast
										response to issues. {process.env.REACT_APP_NAME} thanks for
										always being true.
									</p>
								</div>
								<div className="d-flex justify-content-end">
									<FaQuoteRight />
								</div>
								<div className="d-flex text-white gap-2 align-items-center px-3">
									<img
										src={require("../../Assets/testimony-img-1.png")}
										alt=""
									/>
									<div>
										<p className="mb-0 text-capitalize fw-semibold">user</p>
										<p className="text-mute mb-0 text-uppercase">ceo</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div
								data-aos="zoom-in-up"
								className="testimony text-white p-4 rounded-3">
								<div className="icon">
									<FaQuoteLeft />
								</div>
								<div className="px-3">
									<p className="text-white">
										I can't imagine managing my business without this website.
										It's made everything so much easier! I love the
										communication aspect of this website
									</p>
								</div>
								<div className="d-flex justify-content-end">
									<FaQuoteRight />
								</div>
								<div className="d-flex text-white gap-2 align-items-center px-3">
									<img
										src={require("../../Assets/testimony-img-1.png")}
										alt=""
									/>
									<div>
										<p className="mb-0 text-capitalize fw-semibold">user</p>
										<p className="text-mute mb-0 text-uppercase">ceo</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div
								data-aos="zoom-in-up"
								className="testimony text-white p-4 rounded-3">
								<div className="icon">
									<FaQuoteLeft />
								</div>
								<div className="px-3">
									<p className="text-white">
										I've tried a lot of financial management tools, but this one
										is by far the best. It's intuitive and user-friendly, even
										for someone like me who isn't great with technology.
									</p>
								</div>
								<div className="d-flex justify-content-end">
									<FaQuoteRight />
								</div>
								<div className="d-flex text-white gap-2 align-items-center px-3">
									<img
										src={require("../../Assets/testimony-img-1.png")}
										alt=""
									/>
									<div>
										<p className="mb-0 text-capitalize fw-semibold">user</p>
										<p className="text-mute mb-0 text-uppercase">ceo</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="py-5 about">
				<div data-aos="fade-up" data-aos-duration="3000">
					<p className="text-center fs-3 fw-bold">Join our newsletter</p>
					<div
						style={{ maxWidth: "400px" }}
						class="input-group mb-3 mx-auto mt-3">
						<input
							type="email"
							class="form-control"
							placeholder="your email address"
							aria-label="Recipient's username"
							aria-describedby="basic-addon2"
						/>
						<button class="input-group-text bg-dark text-white" id="">
							Subscribe
						</button>
					</div>
					<p className="text-center text-muted small mt-5">
						We will send you weekly updates for your better <br /> finance
						management.
					</p>
				</div>
			</section>
			<footer className="py-5">
				<div className="container text-white">
					<div className="row">
						<div className="col-md-3">
							<AppLogo type="white" height="60px" />
							<p className="small my-2">Address here</p>
							<p className="small">
								Lorem ipsum dolor sit amet consectetur. Eu <br /> ut egestas
								elit ut orci. Bibendum lobortis amet.
							</p>
						</div>
						<div className="col-md-7">
							<div className="row">
								<div className="col">
									<div className="w-fit py-4">
										<p className="h5 fw-semibold text-capitalize">explore</p>
										<ul className="p-0">
											<li className="list-group">About</li>
											<li className="list-group">Contact</li>
											<li className="list-group">Services</li>
										</ul>
									</div>
								</div>
								<div className="col">
									<div className="w-fit py-4">
										<p className="h5 fw-semibold text-capitalize">
											our services
										</p>
										<ul className="p-0">
											<li className="list-group">Airtime Services</li>
											<li className="list-group">Data Services</li>
											<li className="list-group">Cable Subscription</li>
											<li className="list-group">Tv Subscription</li>
											<li className="list-group">Tv Subscription</li>
										</ul>
									</div>
								</div>
								<div className="col">
									<div className="w-fit py-4">
										<p className="h5 fw-semibold text-capitalize">Contact Us</p>
										<ul className="p-0">
											<li className="list-group">+234 800 000 0000</li>
											<li className="list-group">+234 800 000 0000</li>
											<li className="list-group">
												{process.env.REACT_APP_NAME}.com
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-2">
							<div className="w-fit py-4">
								<p className="h5 fw-semibold text-capitalize">Contact Us</p>
								<div>socials</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default HomeOne;
