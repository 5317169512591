import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../Reducer/ErrorReducer";
import {
	ADD_AUTO_BUY,
	ADD_AUTO_BUY_FAIL,
	ADD_DATA_BUNDLE,
	ADD_DATA_BUNDLE_FAIL,
	DELETE_AUTO_BUY,
	DELETE_DATA_BUNDLE,
	GET_AUTO_BUY,
	GET_AUTO_BUY_FAIL,
	GET_CABLES_TYPES,
	GET_CABLE_DIRECT_PACKAGE,
	GET_CABLE_DIRECT_PACKAGE_FAIL,
	GET_DATA_TO_BUY,
	GET_DATA_TO_BUY_FAIL,
	GET_EDUCATION_TO_BUY,
	GET_EDUCATION_TO_BUY_FAIL,
	GET_ELECTRICITY_TO_BUY,
	GET_ELECTRICITY_TO_BUY_FAIL,
	SEARCH_AUTO_BUY,
	SEARCH_AUTO_BUY_FAIL,
	SEARCH_AUTO_BUY_LOADING,
	SET_SUCCESS,
	UPDATE_AUTO_BUY,
	UPDATE_DATA_BUNDLE,
} from "./ActionTypes";
import { useURL, useURL8, useURL5 } from "../Config";

export const manageDataBundle = (data, id, type) => async dispatch => {
	try {
		let res;
		if (!id)
			res = await axios.post(
				`/api/v2/data`,
				{ ...data },
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
		else if (type === "delete") res = await axios.delete(`/api/v2/data/${id}`);
		else res = await axios.put(`/api/v2/data/${id}`, { ...data });
		dispatch({
			type: id
				? type === "delete"
					? DELETE_DATA_BUNDLE
					: UPDATE_DATA_BUNDLE
				: ADD_DATA_BUNDLE,
			payload: type === "delete" ? data : res.data?.data,
		});
		dispatch(getDataToBuy());
		dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
	} catch (err) {
		console.log({ err });
		let error = err.response?.data?.error;
		if (error) {
			dispatch(returnErrors({ error, status: err?.response?.status }));
			// error.forEach(error =>
			// 	error?.param
			// 		? error?.param !== "suggestion" &&
			// 		  toast.error(error.msg, { autoClose: false })
			// 		: toast.error(error.msg, { autoClose: false })
			// );
		}
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch({ type: ADD_DATA_BUNDLE_FAIL });
	}
};

export const getDataToBuy = () => async dispatch => {
	try {
		let res = await axios.get(`/api/v2/data`, {
			baseURL: useURL5 || useURL8 || useURL,
			// baseURL: type === "data" ? useURL2 || useURL : useURL,
		});

		dispatch({
			type: GET_DATA_TO_BUY,
			payload: res.data,
		});
	} catch (err) {
		if (err) console.log({ err });
		let error = err.response?.data?.error;
		console.log({ error });
		dispatch({
			type: GET_DATA_TO_BUY_FAIL,
		});
	}
};

export const getElectricityToBuy = () => async dispatch => {
	try {
		let res = await axios.get(`/api/v2/electricity`);

		dispatch({
			type: GET_ELECTRICITY_TO_BUY,
			payload: res.data,
		});
	} catch (err) {
		if (err) console.log({ err });
		let error = err.response?.data?.error;
		console.log({ error });
		dispatch({
			type: GET_ELECTRICITY_TO_BUY_FAIL,
		});
	}
};

export const getCablesPackages = () => async dispatch => {
	try {
		let res = await axios.get(`/api/v2/cables`, {
			baseURL: useURL8 || useURL,
			// baseURL: type === "data" ? useURL2 || useURL : useURL,
		});
		dispatch({
			type: GET_CABLE_DIRECT_PACKAGE,
			payload: res.data,
		});
	} catch (err) {
		if (err) console.log({ err });
		let error = err.response?.data?.error;
		if (error) console.log({ error });
		dispatch({
			type: GET_CABLE_DIRECT_PACKAGE_FAIL,
		});
	}
};

export const getCablesTypes = () => async dispatch => {
	try {
		let res = await axios.get(`/api/v2/cables/types`);

		dispatch({
			type: GET_CABLES_TYPES,
			payload: res.data,
		});
	} catch (err) {
		if (err) console.log(err.response?.data?.error, { err });
		if (err?.response?.status === 429 || err?.response?.status === 405)
			toast.error(err?.response?.data ? err?.response?.data : err?.message);
	}
};

export const getAutoBuy = data => async dispatch => {
	try {
		if (data?.search)
			dispatch({ type: SEARCH_AUTO_BUY_LOADING, search: data?.search });
		let res = await axios.get(
			`/api/v2/auto-buy?type=all
			${!data?.limit ? "" : `&limit=${data.limit}`}${
				!data?.page ? "" : `&page=${data.page}`
			}
			${!data?.search ? "" : `&search=${data?.search}`}
			`
		);

		dispatch({
			type: data?.search ? SEARCH_AUTO_BUY : GET_AUTO_BUY,
			payload: res.data,
			search: data?.search
				? res?.data?.search
					? res?.data?.search
					: data?.search
				: "",
		});
	} catch (err) {
		if (err) console.log({ err });
		let error = err.response?.data?.error;
		if (data?.search) {
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			}
		}
		dispatch({
			type: data?.search ? SEARCH_AUTO_BUY_FAIL : GET_AUTO_BUY_FAIL,
		});
	}
};

export const manageAutoBuy = (data, id, type) => async dispatch => {
	try {
		let res;
		if (!id) res = await axios.post(`/api/v2/auto-buy`, { ...data });
		else if (type === "delete")
			res = await axios.delete(`/api/v2/auto-buy/${id}`);
		else res = await axios.put(`/api/v2/auto-buy/${id}`, { ...data });
		dispatch({
			type: id
				? type === "delete"
					? DELETE_AUTO_BUY
					: UPDATE_AUTO_BUY
				: ADD_AUTO_BUY,
			payload: type === "delete" ? data : res.data?.data,
		});
		dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
	} catch (err) {
		console.log({ err });
		let error = err.response?.data?.error;
		if (error) {
			dispatch(returnErrors({ error, status: err?.response?.status }));
		}
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch({ type: ADD_AUTO_BUY_FAIL });
	}
};

export const getEducationToBuy = () => async dispatch => {
	try {
		let res = await axios.get(`/api/v2/education`);

		dispatch({
			type: GET_EDUCATION_TO_BUY,
			payload: res.data,
		});
	} catch (err) {
		if (err) console.log({ err });
		let error = err.response?.data?.error;
		console.log({ error });
		dispatch({
			type: GET_EDUCATION_TO_BUY_FAIL,
		});
	}
};
