import { Link } from "react-router-dom";
// import whiteLogo from "../Assets/honorworld-brand.png";
// import darkLogo from "../Assets/honorworld-brand.png";

const AppLogo = ({ height, type }) => {
  return (
    <Link to="/" className="">
      {type === "dark" ? (
        <img
          src={process.env.REACT_APP_IMAGE_URL}
          alt={process.env.REACT_APP_NAME}
          // style={{ height: height }}
          className="tw-h-14"
        />
      ) : (
        <img
          src={
            process.env.REACT_APP_IMAGE_URL_LIGHT ||
            process.env.REACT_APP_IMAGE_URL
          }
          // style={{ height: height }}
          className="tw-h-14"
          alt={process.env.REACT_APP_NAME}
        />
      )}
    </Link>
  );
};

export default AppLogo;
