import {
	GET_BILLER,
	GET_BILLER_FAIL,
	GET_CATEGORY,
	GET_CATEGORY_FAIL,
	GET_GATEWAY,
	GET_GATEWAY_FAIL,
	GET_NETWORK,
	GET_PRODUCTS,
	GET_PRODUCTS_FAIL,
	LOGOUT,
	SEARCH_BILLER,
	SEARCH_BILLER_FAIL,
	SEARCH_BILLER_LOADING,
	SEARCH_BILLER_RELOAD,
	SEARCH_CATEGORY,
	SEARCH_CATEGORY_FAIL,
	SEARCH_CATEGORY_LOADING,
	SEARCH_CATEGORY_RELOAD,
	SEARCH_PRODUCTS,
	SEARCH_PRODUCTS_FAIL,
	SEARCH_PRODUCTS_LOADING,
	SEARCH_PRODUCTS_RELOAD,
} from "../Actions/ActionTypes";

let initialStateA = {
	data: null,
	isAdded: false,
	isUpdated: false,
	isDeleted: false,
};

export const NetworkReducer = (state = initialStateA, action) => {
	let { type, payload } = action;
	let data = payload?.data ? payload?.data : payload;

	switch (type) {
		case GET_NETWORK:
			return {
				data,
			};
		case LOGOUT:
			return initialStateA;
		default:
			return state;
	}
};

const initialState4 = {
	isLoading: false,
	data: [],
	isAdded: false,
	isUpdated: false,
	paginate: null,
	isDeleted: null,
	isFound: null,
	searchLoading: null,
	mainSearch: [],
	search: "",
	search_paginate: null,
};

export const BillerReducer = (state = initialState4, action) => {
	const { type, payload } = action;
	let data = payload?.data ? payload?.data : payload;

	switch (type) {
		case SEARCH_BILLER:
			return {
				...state,
				isFound: true,
				searchLoading: false,
				mainSearch: data ? data : [],
				search: action.search,
				search_paginate: payload?.paginate,
			};
		case SEARCH_BILLER_FAIL:
			return {
				...state,
				searchLoading: false,
				mainSearch: state.mainSearch,
				search_paginate: state.search_paginate,
			};
		case SEARCH_BILLER_LOADING:
			return {
				...state,
				searchLoading: true,
			};
		case SEARCH_BILLER_RELOAD:
			return {
				...state,
				isFound: false,
				searchLoading: false,
			};
		case GET_BILLER:
			return {
				...state,
				isLoading: false,
				data: data ? data : [],
				paginate: payload?.paginate,
			};
		case GET_BILLER_FAIL:
			return {
				...state,
				isLoading: false,
			};
		case LOGOUT:
			return initialState4;
		default:
			return state;
	}
};

const initialState5 = {
	isLoading: false,
	data: [],
	isAdded: false,
	isUpdated: false,
	paginate: null,
	isDeleted: null,
	isFound: null,
	searchLoading: null,
	mainSearch: [],
	search: "",
	search_paginate: null,
};

export const CategoryReducer = (state = initialState5, action) => {
	const { type, payload } = action;
	let data = payload?.data ? payload?.data : payload;

	switch (type) {
		case SEARCH_CATEGORY:
			return {
				...state,
				isFound: true,
				searchLoading: false,
				mainSearch: data ? data : [],
				search: action.search,
				search_paginate: payload?.paginate,
			};
		case SEARCH_CATEGORY_FAIL:
			return {
				...state,
				searchLoading: false,
				mainSearch: state.mainSearch,
				search_paginate: state.search_paginate,
			};
		case SEARCH_CATEGORY_LOADING:
			return {
				...state,
				searchLoading: true,
			};
		case SEARCH_CATEGORY_RELOAD:
			return {
				...state,
				isFound: false,
				searchLoading: false,
			};
		case GET_CATEGORY:
			return {
				...state,
				isLoading: false,
				data: data ? data : [],
				paginate: payload?.paginate,
			};
		case GET_CATEGORY_FAIL:
			return {
				...state,
				isLoading: false,
			};
		case LOGOUT:
			return initialState5;
		default:
			return state;
	}
};

const initialState6 = {
	isLoading: false,
	data: [],
	isAdded: false,
	isUpdated: false,
	paginate: null,
	isDeleted: null,
	isFound: null,
	searchLoading: null,
	mainSearch: [],
	search: "",
	search_paginate: null,
};

export const ProductReducer = (state = initialState6, action) => {
	const { type, payload } = action;
	let data = payload?.data ? payload?.data : payload;

	switch (type) {
		case SEARCH_PRODUCTS:
			return {
				...state,
				isFound: true,
				searchLoading: false,
				mainSearch: data ? data : [],
				search: action.search,
				search_paginate: payload?.paginate,
			};
		case SEARCH_PRODUCTS_FAIL:
			return {
				...state,
				searchLoading: false,
				mainSearch: state.mainSearch,
				search_paginate: state.search_paginate,
			};
		case SEARCH_PRODUCTS_LOADING:
			return {
				...state,
				searchLoading: true,
			};
		case SEARCH_PRODUCTS_RELOAD:
			return {
				...state,
				isFound: false,
				searchLoading: false,
			};
		case GET_PRODUCTS:
			return {
				...state,
				isLoading: false,
				data: data ? data : [],
				paginate: payload?.paginate,
			};
		case GET_PRODUCTS_FAIL:
			return {
				...state,
				isLoading: false,
			};
		case LOGOUT:
			return initialState6;
		default:
			return state;
	}
};


const initialGate = {
	isLoading: false,
	data: [],
	paginate: null,
};

export const GatewayReducer = (state = initialGate, action) => {
	const { type, payload } = action;
	let data = payload?.data ? payload?.data : payload;

	switch (type) {
		case GET_GATEWAY:
			return {
				...state,
				isLoading: false,
				data: data || [],
				paginate: payload?.paginate,
			};
		case GET_GATEWAY_FAIL:
			return {
				...state,
				isLoading: false,
			};
		case LOGOUT:
			return initialGate;
		default:
			return state;
	}
};