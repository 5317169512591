import { motion } from "framer-motion";

const AnimaionLayout = ({ children }) => {
  return (
    <motion.section
      initial={{ opacity: 0, scaleX: 0.3, scaleY: 0.3 }}
      whileInView={{ opacity: 1, scaleX: 1.0, scaleY: 1 }}
      transition={{
        duration: 1.5,
        ease: "easeIn",
        type: "spring",
        stiffness: 50,
        mass: 0.8,
      }}
    >
      {" "}
      {children}
    </motion.section>
  );
};

export default AnimaionLayout;