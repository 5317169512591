import React, { useContext, useEffect } from "react";
import { GlobalState } from "../../Data/Context";
import { Container } from "reactstrap";
import { BonusCommission } from "../../Components/Wallets";
import { NumericFormat } from "react-number-format";

const Bonus = () => {
	let { setStateName } = useContext(GlobalState);
	useEffect(() => {
		setStateName("Bonus history");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="bg-white aboutScreen">
			<Container className="py-3 py-md-5">
				<h5 className="Lexend">Bonus History</h5>
				<BonusCommission type={"bonus"} />{" "}
			</Container>{" "}
		</div>
	);
};

export default Bonus;

export const WalletForm = ({ state, textChange, setState }) => {
	let { nairaSignNeutral } = useContext(GlobalState);
	return (
		<form className="row mx-0">
			<div className="form mb-3">
				<label htmlFor="type">Type</label>
				<select
					value={state?.type}
					onChange={textChange("type")}
					className="form-control rounded10 py-3 form-select">
					<option value="">Choose type</option>
					<option value="wallet">Wallet ID</option>
					<option value="email">User Email</option>
					<option value="telephone">User Number</option>
				</select>
			</div>
			<div className="form mb-3">
				<label htmlFor="id">
					{state?.type === "email"
						? "Email"
						: state?.type === "telephone"
						? "Telephone"
						: "ID"}
				</label>
				<input
					type={state?.type === "email" ? "email" : "tel"}
					name={
						state?.type === "email"
							? "email"
							: state?.type === "telephone"
							? "telephone"
							: "walletId"
					}
					value={state?.user}
					onChange={textChange("user")}
					className="form-control rounded10 py-3"
					placeholder={
						state?.type === "email"
							? "example@mail.com"
							: state?.type === "telephone"
							? "0800 0000 000"
							: "1234567890"
					}
					maxLength={
						state?.type === "telephone"
							? 11
							: state?.type === "wallet"
							? 8
							: false
					}
				/>
			</div>
			<div className="form mb-3">
				<label htmlFor="value">Amount</label>
				{/* <input
							type={"number"}
							placeholder="500"
							className="form-control py-3"
							value={state?.amount}
							onChange={textChange("amount")}
							min={0}
						/> */}
				<NumericFormat
					prefix={`${nairaSignNeutral} `}
					className="form-control rounded10 py-3"
					value={state.amount}
					placeholder="500"
					displayType="input"
					thousandSeparator={true}
					onValueChange={val => setState({ ...state, amount: val?.floatValue })}
					min={0}
					inputMode="decimal"
					renderText={(value, props) => <span {...props}>{value}</span>}
					allowNegative={false}
				/>
			</div>
		</form>
	);
};
